import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";

const FormTextField: React.FC<any> = ({
  label,
  control,
  name,
  defaultValue,
  placeholder,
  required,
  type
}) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value } }) => (
        <IonItem className="w-96 mt-8 flex flex-col">
          <IonLabel position="stacked">
            <IonText>{label}</IonText>
          </IonLabel>
          <IonInput
            value={type === 'number' ? parseInt(value) : value}
            placeholder={placeholder}
            onIonChange={onChange}
            onIonBlur={onBlur}
            required={required || false}
            type={type}
          />
        </IonItem>
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export default FormTextField;
