import Auth from "@aws-amplify/auth";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import NegotiationListItem from "./NegotiationListItem";

const NegotiationList: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>();
  const [, listOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const foundOffersToUser: Offer[] = await listOffers({
          queryParams: { toId: user.username, pending: true },
        });
        const foundOffersFromUser: Offer[] = await listOffers({
          queryParams: { byId: user.username, pending: true },
        });
        var totalOffers = foundOffersFromUser.concat(foundOffersToUser);
        totalOffers = totalOffers.reverse();
        setOffers(totalOffers);
      } catch {}
    })();
  }, [listOffers]);

  useIonViewWillEnter(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const foundOffersToUser: Offer[] = await listOffers({
          queryParams: { toId: user.username, pending: true },
        });
        const foundOffersFromUser: Offer[] = await listOffers({
          queryParams: { byId: user.username, pending: true },
        });
        const totalOffers = foundOffersFromUser.concat(foundOffersToUser);
        setOffers(totalOffers);
      } catch {}
    })();
  });

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/content"/>
          </IonButtons>
          <IonTitle>Cash Offers</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {offers?.map((offer) => (
            <NegotiationListItem key={offer.id} offer={offer} />
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default NegotiationList;
