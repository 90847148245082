import { IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import { arrowForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import ContentCard from "./ContentCard";

const CommitListItem: React.FC<any> = (props) => {
  const commit: Commit = props.commit;
  const [campaign, setCampaign] = useState<Campaign>();
  const [, getCampaign] = useApi("GET", "campaign", "fetch");

  useEffect(() => {
    (async () => {
      try {
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: commit.campaignId },
        });
        setCampaign(foundCampaign);
      } catch {}
    })();
  }, [getCampaign, commit]);

  const displayContent = () => {
    if (
      commit.state === "AwaitingApproval" ||
      commit.state === "ContentPending" ||
      commit.state === "Declined"
    ) {
      return false;
    }
    return true;
  };

  return (
    <React.Fragment>
      {!displayContent() && (
        <IonItem className="flex flex-col">
          <IonLabel class="ion-text-wrap">
            <IonText>{campaign?.title}</IonText>
            {commit?.revisionRequested && commit?.state === "ContentPending" && (
              <IonText className="text-red-500">
                <br />
                Resubmit: {commit.reasonForDecline}
              </IonText>
            )}
          </IonLabel>

          {commit.state === "ContentPending" && (
            <IonIcon
              icon={arrowForward}
              slot="end"
              className="fill-current  ml-10"
            />
          )}
        </IonItem>
      )}
      {displayContent() && (
        <div className="flex place-content-center place-items-center">
          <ContentCard commit={commit} campaign={campaign} />
        </div>
      )}
    </React.Fragment>
  );
};

export default CommitListItem;
