import Auth from "@aws-amplify/auth";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { useEffect, useState } from "react";
import PaymentListItem from "./PaymentListItem";

const Payments: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>();
  const [, listOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const foundOffersFromUser = await listOffers({
        queryParams: { byId: user.username, accepted: true },
      });
      const foundOffersToUser = await listOffers({
        queryParams: { toId: user.username, accepted: true },
      });
      const totalOffers: Offer[] = foundOffersFromUser.concat(
        foundOffersToUser
      );
      setOffers(totalOffers.reverse());
    })();
  }, [listOffers]);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home/account" />
          </IonButtons>
          <IonTitle>Rewards</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col place-items-center place-content-center">
          <IonList className="w-full place-content-center place-items-center">
            {offers?.map((offer) => (
              <PaymentListItem offer={offer} />
            ))}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Payments;
