import Auth from "@aws-amplify/auth";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import Divider from "@uiw/react-divider";
import { compareAsc } from "date-fns";
import { time } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { RouteComponentProps } from "react-router";

const appHostName =
  process.env.REACT_APP_DASHBOARD_HOST || "http://localhost:8100";
interface CampaignDetailsProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const CampaignDetails: React.FC<CampaignDetailsProps> = ({
  match,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [campaignExpired, setCampaignExpired] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [displayQrCode, setDisplayQrCode] = useState(false);
  const [commit, setCommit] = useState<Commit>();

  const [, getCampaign] = useApi("GET", "campaign", "fetch");
  const [, listCommits] = useApi("GET", "commit", "list");
  const [, createCommit] = useApi("POST", "commit", "create");

  useEffect(() => {
    (async () => {
      if (match.params.id) {
        try {
          setLoading(true)
          const foundCampaign: Campaign = await getCampaign({
            queryParams: { campaignId: match.params.id },
          });
          setSelectedCampaign(foundCampaign);
          setCampaignExpired(
            compareAsc(new Date(foundCampaign.endDate), new Date()) === -1
          );
          const userId = await Auth.currentAuthenticatedUser();
          const usersCommits: Commit[] = await listCommits({
            queryParams: {
              userId: userId.username,
              campaignId: foundCampaign.id,
            },
          });
          if (usersCommits.length > 0) {
            setCommit(usersCommits[0]);
            setAlreadyApplied(true);
            setDisplayQrCode(true);
          }
        } catch {}
        finally {
          setLoading(false)
        }
      }
    })();
  }, [match.params.id, getCampaign, listCommits]);

  const getHashTags = () => {
    var toReturn = "";
    selectedCampaign?.hashtags.forEach((hashtag) => {
      toReturn += hashtag + " ";
    });
    return toReturn;
  };

  const requestAccess = async () => {
    if (!alreadyApplied) {
      if (selectedCampaign?.type === "Online") {
        await createCommit({
          body: {
            campaignId: selectedCampaign?.id,
          },
        });
        setAlreadyApplied(true);
      } else if (selectedCampaign?.type === "Physical") {
        const commit: Commit = await createCommit({
          body: {
            campaignId: selectedCampaign?.id,
          },
        });
        setCommit(commit);
        setDisplayQrCode(true);
        setAlreadyApplied(true);
      }
    }
  }

  if(loading) {
    return (
      <IonLoading isOpen={true}/>
    )
  }

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start" className="fill-current">
            <IonBackButton defaultHref="/home/campaigns" />
          </IonButtons>
          {selectedCampaign && (
            <IonTitle>
              {selectedCampaign?.brandName} - {selectedCampaign?.title}
            </IonTitle>
          )}
          {!selectedCampaign && <IonTitle>Error</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="flex flex-col place-content-center place-items-center">
          {selectedCampaign !== undefined && (
            <React.Fragment>
              <div className="place-content-center place-items-center flex flex-col">
                <img
                  src={selectedCampaign.coverImage?.url}
                  style={{ maxHeight: 200 }}
                  className="mb-2 w-full max-h-64 object-cover"
                  alt="campaign cover"
                />
                <p className="font-bold text-xl">
                  {selectedCampaign?.brandName}
                </p>
                <p className="font-bold text-lg">{selectedCampaign.title}</p>
                <p className="text-lg">{selectedCampaign?.description}</p>
                <React.Fragment>
                  <p className="fill-current text-gray-500">
                    <IonIcon icon={time} className="mr-2" />
                    {new Date(selectedCampaign.startDate).toDateString()}
                  </p>
                </React.Fragment>
              </div>
              <Divider />
              <div className="w-full flex flex-col place-items-center place-content-center">
                <p className="font-bold">VISUAL DIRECTION</p>
                <IonSlides className="mt-2 w-40" mode="ios" pager>
                  {selectedCampaign.moodboard.map((img) => {
                    return (
                      <IonSlide key={img.id}>
                        <img src={img.url} alt="mood" />
                      </IonSlide>
                    );
                  })}
                </IonSlides>
                <Divider />
                <p className="font-bold">Content Guidelines</p>
                <ul className="text-center list-disc">
                  {selectedCampaign.rules.map((rule) => (
                    <li key="rule">{rule}</li>
                  ))}
                </ul>
                {selectedCampaign.captionRules !== null && (
                  <React.Fragment>
                    <p className="font-bold">Caption Guidelines</p>
                    <ul className="text-center list-disc">
                      {selectedCampaign.captionRules.map((rule) => (
                        <li key="rule">{rule}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                )}

                <Divider />
                <p className="font-bold">Target Audience</p>
                <p className="text-center m-2">
                  {selectedCampaign.callToAction}
                </p>
                <Divider />
                <p className="font-bold">REWARD</p>
                <p className="text-center m-2">
                  {selectedCampaign.rewardType} -{" "}
                  {selectedCampaign.rewardDescription}
                </p>
              </div>
              <Divider />
              <div className="flex flex-col place-content-center place-items-center">
                {selectedCampaign.type === "Physical" && (
                  <React.Fragment>
                    <p className="font-bold">ADDRESS</p>
                    <p className="text-center">
                      {selectedCampaign.addressFormatted}
                    </p>
                    <Divider />
                  </React.Fragment>
                )}
                <React.Fragment>
                  <p className="font-bold">Our Instagram</p>
                  <p>{selectedCampaign.atHandle}</p>
                  <Divider />
                </React.Fragment>

                {selectedCampaign.url && selectedCampaign.url !== "" && (
                  <React.Fragment>
                    <p className="font-bold">Our Website</p>
                    <p>{selectedCampaign.url}</p>
                    <Divider />
                  </React.Fragment>
                )}

                {selectedCampaign.hashtags !== null &&
                  selectedCampaign.hashtags !== undefined && (
                    <React.Fragment>
                      <p className="font-bold">Hashtags</p>
                      <p>{getHashTags()}</p>
                      <Divider />
                    </React.Fragment>
                  )}
              </div>

              <div className="flex flex-col place-content-center place-items-center">
                <p className="font-bold">AVAILABLE UNTIL</p>
                <p className="mb-1 text-center ml-2 mr-2">
                  Until{" "}
                  {new Date(selectedCampaign.endDate).toLocaleDateString()} or
                  there is no redemptions remaining or budget is spent.
                </p>
              </div>

              {displayQrCode &&
                alreadyApplied &&
                commit &&
                selectedCampaign.type === "Physical" && (
                  <div className="flex flex-col place-items-center place-content-center">
                    <Divider />
                    <QRCode
                      value={`${appHostName}/offer/${commit.id}/redeem`}
                      logoImage="https://i.imgur.com/5ijJp4O.png"
                      size={300}
                      logoWidth={400}
                      logoHeight={400}
                      logoOpacity={0.5}
                      qrStyle={"dots"}
                    />

                    <IonText className="ml-2 mr-2 text-center text-xl font-bold">
                      Ask a member of staff at <br />{" "}
                      {selectedCampaign?.brandName},{" "}
                      {selectedCampaign?.addressFormatted} <br /> to scan this
                      QR code to claim your reward
                    </IonText>
                  </div>
                )}

              {selectedCampaign.type === "Online" && !campaignExpired && (
                <IonButton
                  onClick={() => requestAccess()}
                  fill="outline"
                  disabled={alreadyApplied}
                  className="mb-10"
                >
                  {alreadyApplied ? "ALREADY REQUESTED" : "REQUEST ACCESS"}
                </IonButton>
              )}

              {selectedCampaign.type === "Physical" && !campaignExpired && (
                <IonButton
                  fill="outline"
                  className="mb-10"
                  onClick={() => requestAccess()}
                  disabled={alreadyApplied}
                >
                  {alreadyApplied ? "ALREADY APPLIED" : "REDEEM NOW"}
                </IonButton>
              )}
            </React.Fragment>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CampaignDetails;
