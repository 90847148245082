import Amplify, { Hub } from "@aws-amplify/core";
import { getAmplifyConfigForEnv } from "@socialplug/common/amplify.config";
import {
  plugin_annotate,
  plugin_crop,
  plugin_filter,
  setPlugins as setDokaPlugins,
} from "@socialplug/common/libs/doka/doka";
import log from "loglevel";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

setDokaPlugins(plugin_crop, plugin_filter, plugin_annotate);

log.setLevel("DEBUG");
Amplify.configure(getAmplifyConfigForEnv());

Hub.listen("auth", (data) => {
  const { payload } = data;
  log.info("Authentication Event Received: ", payload.event);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
