import { InAppBrowser } from "@ionic-native/in-app-browser";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { globeOutline, logoInstagram, logoTiktok } from "ionicons/icons";
import{ useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";

const Profile = () => {

  const { user } = useContext(UserContext)

  const [commits, setCommits] = useState<Commit[]>()
  const [offers, setOffers] = useState<Offer[]>()

  const [, fetchCommits] = useApi("GET", "commit", "list")
  const [, fetchOffers] = useApi("GET", "offer", "list")

  useEffect(() => {
    try {
      (async () => {
        if (user) {
          const foundCommits: Commit[] = await fetchCommits({
            queryParams: { userId: user.id },
          })
          setCommits(foundCommits);
          const foundOffersToUser: Offer[] = await fetchOffers({
            queryParams: { toId: user.id },
          })
          const foundOffersFromUser: Offer[] = await fetchOffers({
            queryParams: { byId: user.id },
          })
          setOffers(foundOffersToUser.concat(foundOffersFromUser))
        }
      })()
    } catch {}
  }, [user, fetchCommits, fetchOffers])

  const completedCommits = (commits ? 
      commits.filter((commit) => commit.state !== 'Completed').length
      : 0
  )

  const offersMade = (offers ?
    offers.filter((offer) => offer.offeredById !== user?.id).length
    : 0
  )

  const acceptedOffers = (offers?
    offers.filter((offer) => !offer.accepted).length
    : 0
  )

  const redirectToPortfolio = () => {
    if(user){
      InAppBrowser.create(user.website)
    }
  }

  const redirectToTiktok = () => {
    InAppBrowser.create(
      `https://www.tiktok.com/@${user?.tiktokAccountHandle}`
    )
  }

  const redirectToInsta = () => {
    InAppBrowser.create(
      `https://www.instagram.com/${user?.instagramAccountHandle.replace('@', '')}`
    )
  }

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/account" />
          </IonButtons>
          <IonTitle>Your Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      {user && (
        <IonContent fullscreen>
          <div className="ml-2 mr-2">
            <div className="flex flex-row place-items-center">
              <IonAvatar className="mt-2 ml-4">
                <img
                  src={user?.profileImage?.url}
                  alt={"user avatar"}
                  className="w-24"
                />
              </IonAvatar>
              <div className="flex flex-col">
              <IonText className="ml-2 text-lg font-bold">{user.name}</IonText>
              <IonText className="ml-2 font-bold">{user.followers} Followers</IonText>
              </div>
            </div>
            <div className="flex flex-col ml-2 place-content-start place-items-start">
              {user.bio !== '' && (
                <IonText>Bio: {user.bio}</IonText>
              )}
              {user.location !== '' && (
                <IonText>Location: {user.location}</IonText>
              )}
              {user.shippingAddress !== '' && (
                <IonText>Your Shipping Address: {user.shippingAddress}</IonText>
              )}
            </div>
            <div className="w-full my-2 flex flex-row place-content-center place-items-center">
              {user.tiktokAccountHandle && user.tiktokAccountHandle !== ''  && (
                <IonButton fill="outline" onClick={redirectToTiktok} className="w-2/3 rounded-xl">
                  <IonIcon icon={logoTiktok} className="mr-2" />
                  Tiktok
                </IonButton>
              )}
              {user.instagramAccountHandle && user.instagramAccountHandle !== '' && (
                <IonButton fill="outline" onClick={redirectToInsta} className="w-2/3 rounded-xl">
                  <IonIcon icon={logoInstagram} className="mr-2" />
                  Instagram
                </IonButton>
              )}
            </div>
            {user.website && user.website !== '' && (
                <div className='flex place-content-center'>
                  <IonButton fill="outline" onClick={redirectToPortfolio} className="w-1/2 rounded-xl">
                    <IonIcon icon={globeOutline} className="mr-2" />
                    Portfolio
                  </IonButton>
                </div>
              )}
            <div className="flex flex-col place-content-center place-items-center">
              <IonCard className="w-11/12 bg-white rounded-xl">
                <IonCardContent>
                  <div className="m-5 text-center text-black">
                    <IonText>
                      <strong>Campaigns Completed:</strong> {completedCommits}
                    </IonText>
                    <br />
                    <IonText>
                      <strong>Offers Made:</strong> {offersMade}
                    </IonText>
                    <br />
                    <IonText>
                      <strong>Offers Accepted: </strong> {acceptedOffers}
                    </IonText>
                  </div>
                </IonCardContent>
              </IonCard>
              {user.interests && user.interests.length > 0 && (
                <IonCard className="w-11/12 bg-white">
                  <IonCardContent>
                    <div className="m-5 text-center text-black place-content-center place-items-center">
                      <IonText className="text-lg font-bold">
                        Your Interests:
                      </IonText>
                      <IonText>
                        <ul className="place-content-center place-items-center flex flex-col">
                          {user.interests.map((interest) => (
                            <li
                              className="text-center list-disc"
                              key={interest}
                            >
                              {interest}
                            </li>
                          ))}
                        </ul>
                      </IonText>
                    </div>
                  </IonCardContent>
                </IonCard>
              )}
              <IonText className="font-bold">Your Photos</IonText>
              <div className='grid grid-cols-2 mx-4'>
                {user.imagePortfolio && user.imagePortfolio.map((image) => (
                  <IonCard className="bg-white rounded-xl" key={image.id}>
                    <img src={image.url} alt={`portfolio-${image.id}`} className='p-1 rounded-xl'/>
                  </IonCard>
                ))}
              </div>
            </div>

            <div className="flex flex-col place-content-center place-items-center mb-5">
              <IonText className="font-bold">Your Videos</IonText>
              {user.videoPortfolio && user.videoPortfolio.map((field) => (
                <IonCard className="bg-white rounded-xl w-5/6" key={field.id}>
                  <video 
                    className="h-full rounded-xl p-1"
                    controls
                    key={field.id}
                  >
                    <source src={field.url} type="video/mp4" />
                  </video>
                </IonCard>
              ))}
            </div>

          </div>
        </IonContent>
      )}
    </IonPage>
  );
};

export default Profile;
