import { Auth } from "@aws-amplify/auth";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useApi } from "@socialplug/common/utilities/api";
import log from "loglevel";
import React, { useState } from "react";
import LoadingButton from "../../components/LoadingButton";
import emailRegex from "../../util/emailRegex";

type AuthMode = "signup" | "signin" | "requirecode" | "reset-password" | "reset-password-confirm"

const SignIn: React.FC = () => {
  const [present] = useIonAlert()

  const [authMode, setAuthMode] = useState<AuthMode>('signin')
  
  const [email, setEmail] = useState('')
  const [signInLoading, setSignInLoading] = useState(false)
  const [validEmail, setValidEmail] = useState<boolean>()
  const [verifyingEmail, setVerifyingEmail] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verificationCode, setVerificationCode] = useState('')

  const [, createCreator] = useApi("POST", "creator", "create")

  const signIn = async () => {
    try {
      setSignInLoading(true)
      const response = await Auth.signIn({
        username: email,
        password,
      })
      log.info(response)
      if(response.attributes['custom:account_type'] !== 'CREATOR'){
        throw new Error('not a creator account')
      }
      window.location.href = '/home/campaigns'
    } catch (err) {
      log.error(err)
      if (err.code === "UserNotConfirmedException") {
        setAuthMode('requirecode')
      } else if (err.code === 'PasswordResetRequiredException') {
        setAuthMode('reset-password-confirm')
      } else {
        present({
          header: "Could not authorize",
          subHeader:
            "Make sure you've entered your details correctly",
        })
      }
    } finally {
      setSignInLoading(false)
    }
  }

  const signUp = async () => {
    if (confirmPassword !== password) {
      present({
        header: 'Could not create account',
        subHeader: 'The passwords you entered do not match'
      })
    } else {
      try {
        setSignInLoading(true)
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            'custom:account_type':'CREATOR'
          }
        })
        setAuthMode('requirecode')
      } catch {
        present({
          header: 'Could not create account',
          subHeader: 'Something went wrong, please try again later'
        })
      } finally {
        setSignInLoading(false)
      }
    }
  }

  const flipAuthMode = () => {
    setAuthMode(authMode === 'signin' ? 'signup' : 'signin')
  }

  const resendVerificationCode = async () => {
    if(authMode === 'requirecode'){
      await Auth.resendSignUp(email)
    } else if (authMode === 'reset-password-confirm') {
      await Auth.forgotPassword(email)
    }
  }

  const resetPassword = async () => {
    await Auth.forgotPassword(email)
    setAuthMode('reset-password-confirm')
  }

  const verifyEmail = async () => {
    try { 
      setVerifyingEmail(true)
      await Auth.confirmSignUp(email, verificationCode)
      await Auth.signIn({ username: email, password })
      await createCreator({ body: { name: email } })
      window.location.href = "/auth/complete"
    } catch (err) {
      log.info(err)
      present({
        header: 'Could not verify email',
      })
    } finally {
      setVerifyingEmail(false)
    }
  }

  const confirmPasswordReset = async () => {
    try {
      if (password !== confirmPassword) {
        present({
          header: 'Could not create account',
          subHeader: 'The passwords you entered do not match'
        })
      } else {
        await Auth.forgotPasswordSubmit(email, verificationCode, password)
        if(password !== '') {
          await Auth.signIn(email, password)
          window.location.href = '/home/campaigns'
        }
      }
    } catch (err) {
      present({
        header: 'Could not reset password',
        subHeader: 'Something went wrong, please try again later'
      })
    }
  }

  const checkEmailRegex = (email: string) => {
    setValidEmail(emailRegex.test(email))
  }

  const privacyPolicyOnClick = () => {
    window.location.href = "https://socialplug.co.uk/privacy-policy/";
  };

  const tosOnClick = () => {
    window.location.href = "https://www.socialplug.co.uk/terms-of-use";
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle id="title" className="font-bold">
            Welcome to Social Plug
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class='overflow-y-scroll'>
        <div className="flex flex-col place-content-center place-items-center">
          {authMode !== 'signup' && (
            <React.Fragment>
              <img
                src="/assets/logo.png"
                alt="logo"
                className="my-5 h-60 lg:h-72"
              />
              <IonText className="text-center px-2">
                Connect with your favourite local and online brands and get rewarded
              </IonText>
            </React.Fragment>
          )}
          {['signin', 'signup', 'reset-password'].includes(authMode) && (
            <React.Fragment>
              <IonItem 
                style={{'--highlight-background': '#FFC249'}}
                className="w-4/5 mb-4 border-2 border-black rounded-xl mt-5"
              >
                <IonInput
                  placeholder="Your Email"
                  onIonChange={(e) => setEmail(e.detail.value!)}
                  onIonBlur={() => checkEmailRegex(email)}
                  className="text-lg"
                ></IonInput>
              </IonItem>
              {!validEmail && email !== '' && (
                <p className="text-red-500">Invalid email</p>
              )}
            </React.Fragment>
          )}

          {['signup', 'signin', 'reset-password-confirm'].includes(authMode) && (
            <React.Fragment>
            <IonItem
              style={{'--highlight-background': '#FFC249'}}
              className="w-4/5 border-2 border-black rounded-xl"
            >
              <IonInput
                type="password"
                placeholder="Password"
                onIonChange={(e) => setPassword(e.detail.value!)}
                className="text-lg"
              ></IonInput>
            </IonItem>
            {authMode === 'signup' && (
              <IonText className="text-center text-sm mx-4">
                Min 8 Characters. Must contain a number and a capital letter
              </IonText>
            )}
            </React.Fragment>
          )}
             
          {['signup', 'reset-password-confirm'].includes(authMode) && (
            <IonItem 
              style={{'--highlight-background': '#FFC249'}}
              className="w-4/5 mb-4 mt-2 border-2 border-black rounded-xl"
            >
              <IonInput
                type="password"
                placeholder='Confirm Password'
                onIonChange={(e) => setConfirmPassword(e.detail.value!)} 
                className="text-lg"
              ></IonInput>
            </IonItem>
          )}

          {['signin', 'signup'].includes(authMode) && (
            <React.Fragment>
              <LoadingButton
                onClick={authMode === 'signin' ? signIn : signUp}
                disabled={!validEmail || email === ''}
                loading={signInLoading}
                className="rounded-xl text-black w-40 "
                label={`${authMode === 'signin' ? 'Sign In' : 'Register'}`}
                fill="outline"
              />
              <p className="font-bold text-xl mt-5">
                {authMode === 'signin' ? 'Not got an account?' : 'Already Registered?'}
              </p>
              <IonButton
                onClick={flipAuthMode}
                className="rounded-xl text-black"
                fill="outline"
              >
                {authMode === 'signin' ? 'Register' : 'Sign In'}
              </IonButton>
              <IonButton
                onClick={() => setAuthMode('reset-password')}
                className="rounded-xl text-black"
                fill="outline"
              >
                Reset Password
              </IonButton>
            </React.Fragment>
          )}

          {['reset-password'].includes(authMode) && (
            <>
              <IonButton
                onClick={() => resetPassword()}
                className="rounded-xl text-black mt-4"
                fill="outline"
                disabled={email === ''}
              >
                Reset Password
              </IonButton>
              <IonButton
                onClick={() => setAuthMode('signin')}
                className='rounded-xl text-black mt-4'
                fill='outline'
              >
                Back
              </IonButton>
            </>
          )}

          {['requirecode', 'reset-password-confirm'].includes(authMode) && (
            <React.Fragment>
              <IonItem 
                style={{'--highlight-background': '#FFC249'}}
                className="w-4/5 mb-4 border-2 border-black rounded-xl"
              >
                <IonInput
                  placeholder='Code'
                  onIonChange={(e) => setVerificationCode(e.detail.value!)} 
                  className="text-lg"
                ></IonInput>
              </IonItem>
              
              <LoadingButton
                onClick={authMode === 'requirecode' ? verifyEmail : confirmPasswordReset}
                className="rounded-xl text-black mt-2"
                loading={verifyingEmail}
                fill="outline"
                label={authMode === 'requirecode' ? "Confirm Email" : 'Reset Password'}
              />

              <IonText className="text-center px-2 my-5 text-sm">
                You should have received a code into your inbox.
                You can request new code by clicking on the button below.
              </IonText>

              <IonButton
                onClick={resendVerificationCode}
                className="rounded-xl text-black"
                fill="outline"
              >
                Send Another Code
              </IonButton>
            </React.Fragment>
          )}
          
          <div className="flex flex-grow flex-col justify-end items-center max-w-xs mx-auto pb-8 space-y-2">
            <IonText className="text-sm text-center text-black">
              By proceeding you also agree to the{" "}
              <span onClick={() => tosOnClick()} className="underline">
                Terms of Service
              </span>{" "}
              and{" "}
              <span
                onClick={() => privacyPolicyOnClick()}
                className="underline"
              >
                {" "}
                Privacy Policy
              </span>
            </IonText>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
