import {
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import { useApi } from "@socialplug/common/utilities/api";
import { compareAsc } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import CampaignCard from "../../components/campaigns/campaign-card/CampaignCard";
import UserContext from "../../context/UserContext";

const CampaignsTab: React.FC<RouteComponentProps> = ({ history }) => {
  const [filter, setFilter] = useState("Online");
  const { user } = useContext(UserContext);
  const [assignedCampaign, setAssignedCampaign] = useState<Campaign>();
  const [campaignsToDisplay, setCampaignsToDisplay] = useState<Campaign[]>([]);
  const [, getAssignedCampaign] = useApi("GET", "campaign", "fetch");
  const [, listCampaigns] = useApi("GET", "campaign", "creatorList");

  useEffect(() => {
    (async () => {
      try {
        if (user && user.assignedCampaignId !== "") {
          const foundCampaign = await getAssignedCampaign({
            queryParams: { campaignId: user.assignedCampaignId },
          });
          setAssignedCampaign(foundCampaign);
        }
        if (
          user &&
          (user.status === "Approved" || user.assignedCampaignId !== "")
        ) {
          const campaignList: Campaign[] = await listCampaigns({
            queryParams: { userId: user.id },
          });
          var showCampaigns: Campaign[] = [];
          campaignList.forEach((campaign) => {
            const campaignExpired =
              compareAsc(new Date(campaign.endDate), new Date()) === -1;
            if (!campaignExpired) {
              showCampaigns.push(campaign);
            } else {
            }
          });
          showCampaigns = showCampaigns.reverse();
          setCampaignsToDisplay(showCampaigns);
        }
      } catch {}
    })();
  }, [listCampaigns, getAssignedCampaign]);

  const handleOnClick = (campaign: Campaign) => {
    history.push(`/home/campaigns/view/${campaign.id}`);
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle>Campaigns</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {user && user.status !== "Waiting" && (
          <div className="  flex flex-col gap-2 place-items-center place-content-center">
            <IonSegment
              onIonChange={(e) => setFilter(e.detail.value!)}
              value={filter}
            >
              <IonSegmentButton value="Online" className="w-1/2">
                <IonLabel>Online</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Physical" className="w-1/2">
                <IonLabel>Local</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            <React.Fragment>
              {campaignsToDisplay.map((c) => (
                <React.Fragment key={c.id}>
                  {c.type === filter && (
                    <CampaignCard
                      campaign={c}
                      onClick={() => handleOnClick(c)}
                    />
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          </div>
        )}
        {user &&
          user.status === "Waiting" &&
          user.assignedCampaignId !== "" &&
          assignedCampaign && (
            <div className="flex flex-col place-content-center place-items-center">
              <h1 className="text-yellow-500 text-xl mt-2 text-center mx-4">
                Lets put your content and influence to the test!
              </h1>
              <h1 className="text-yellow-500 text-xl mt-2 text-center mx-4">
                Click the campaign to discover how you can get access to a world
                of opportunities
              </h1>
              <CampaignCard
                campaign={assignedCampaign}
                onClick={() => handleOnClick(assignedCampaign)}
              />
            </div>
          )}
      </IonContent>
    </IonPage>
  );
};

export default CampaignsTab;
