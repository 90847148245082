import * as React from 'react'
import SPError from "../interfaces/error";
export interface ErrorContainer {
    error: SPError|undefined,
    setError: (error: SPError|undefined) => void
}
const ErrorContext = React.createContext<ErrorContainer>({error:undefined, setError: ()=>{console.log('INIT CONTEXT')}})

export default ErrorContext

