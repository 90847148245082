import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import { useEffect } from "react";

const Holding: React.FC<any> = () => {
  const [, fetchMe] = useApi("GET", "creator", "me");

  useEffect(() => {
    (async () => {
      try {
        const timer = setInterval(async () => {
          const user: User = await fetchMe();
          if (
            user && (user.status === "Approved" || user.assignedCampaignId !== "")
          ) {
            window.location.href = ("/home/campaigns");
          }
        }, 15000);
        return () => clearInterval(timer);
      } catch {}
    })();
  }, []);

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle>Welcome to Social Plug</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="flex flex-col place-items-center place-content-center ml-2 mr-2">
          <IonText className="text-center font-bold text-4xl my-3">
            <h1>Thank You For Registering</h1>
          </IonText>
          <img
            src="https://i.imgur.com/s13YTRL.png"
            alt="waiting"
            className="lg:w-2/5"
          />
          <IonText className="text-center font-bold text-2xl">
            <h2>
               You will receive an email or text when you have been accepted.
            </h2>
          </IonText>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Holding;
