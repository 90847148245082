import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonSlide,
  useIonToast,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const ContentCard: React.FC<any> = (props) => {
  const commit: Commit = props.commit
  const campaign: Campaign = props.campaign

  const [present, dismiss] = useIonToast()
  const [offer, setOffer] = useState<Offer>()
  const [, listOffers] = useApi("GET", "offer", "list")

  useEffect(() => {
    (async () => {
      try {
        const foundOffersFromUser: Offer[] = await listOffers({
          queryParams: {
            byId: commit.userId,
            campaignId: campaign.id,
            commitId: commit.id,
            accepted: true,
          },
        });
        const foundOffersToUser: Offer[] = await listOffers({
          queryParams: {
            toId: commit.userId,
            campaignId: campaign.id,
            commitId: commit.id,
            accepted: true,
          },
        });
        const totalOffers = foundOffersFromUser.concat(foundOffersToUser)
        setOffer(totalOffers[0])
      } catch {}
    })();
  }, [campaign, commit, listOffers])


  const copyToClipboardOnClick = () => {
    present({
      buttons: [{ text: "hide", handler: () => dismiss() }],
      message: "post caption copied",
    })
    navigator.clipboard.writeText(commit.postDescription)
  }

  return (
    <IonCard className="max-w-lg bg-white">
      <IonCardHeader className="font-bold text-xl">
        {campaign?.title} - {campaign?.brandName}
      </IonCardHeader>
      <IonCardContent>
        {commit.postType !== "Video" &&
          commit.images &&
          commit.images.length > 0 && (
            <IonSlide>
              <img
                src={commit?.images[0].url}
                alt="campaign cover"
                className="h-40 w-80 abosolute lg:h-64 bg-no-repeat"
              />
            </IonSlide>
          )}
        {commit.postType === "Video" &&
          commit.videos &&
          commit.videos.length > 0 && (
            <video controls>
              <source src={commit.videos[0].url} type="video/mp4" />
            </video>
          )}

        <IonCardSubtitle>{commit?.postDescription}</IonCardSubtitle>
        {commit.commentsToBrand !== "" && commit.state !== "Completed" && (
          <IonCardSubtitle>{commit?.commentsToBrand}</IonCardSubtitle>
        )}

        {offer && (
          <IonCardSubtitle>Reward - £{offer.amount}</IonCardSubtitle>
        )}

        {commit.publicationStatus !== "Published" && (
          <IonCardSubtitle className="font-bold">
            Please make sure to upload your submission where your followers can see it
          </IonCardSubtitle>
        )}
      </IonCardContent>
      {commit.publicationStatus !== "Published" && (
        <IonCardContent className="mt-0">
          <IonButton
            fill="outline"
            style={{
              "--shadow": 0,
            }}
            onClick={() => copyToClipboardOnClick()}
          >
            Copy caption
          </IonButton>
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default ContentCard;
