import { IonButton, IonProgressBar, IonText } from "@ionic/react";
import React from "react";

const LoadingButton: React.FC<any> = (props) => (
  <React.Fragment>
    <IonButton
      fill="outline"
      style={{
        "--shadow": 0,
      }}
      variant={props.variant || "outlined"}
      disabled={props.loading}
      type={props.type}
      {...props}
    >
      <IonText>{props.label}</IonText>
    </IonButton>
    {props.loading && <IonProgressBar type="indeterminate" />}
  </React.Fragment>
);

export default LoadingButton;
