import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Route } from "react-router-dom";
import InboxTab from "../../pages/inbox-tab/InboxTab";

const InboxIndex: React.FC = () => {
  return (
    <React.Fragment>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/home/:tab(inbox)" exact component={InboxTab} />
        </IonRouterOutlet>
      </IonReactRouter>
    </React.Fragment>
  );
};

export default InboxIndex;
