import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import OfferCard from "./OfferCard";

interface NegotiationChatProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const NegotiationChat: React.FC<NegotiationChatProps> = ({
  match,
  history,
}) => {
  const [campaign, setCampaign] = useState<Campaign>();
  const [offer, setOffer] = useState<Offer>();
  const [, getOffer] = useApi("GET", "offer", "fetch");
  const [, getCampaign] = useApi("GET", "campaign", "fetch");

  useEffect(() => {
    (async () => {
      try {
        const foundOffer: Offer = await getOffer({
          queryParams: { offerId: match.params.id },
        });
        setOffer(foundOffer);
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: foundOffer.campaignId },
        });
        setCampaign(foundCampaign);
      } catch {}
    })();
  }, [getCampaign, getOffer, match.params.id]);

  const onAccept = () => {
    history.push("/home/content");
  };

  if(!campaign) {
    return (
      <IonPage>
        <IonLoading isOpen={true}/>
      </IonPage>
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/content/negotiations" />
          </IonButtons>
          {campaign && <IonTitle>Offer from {campaign?.brandName}</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {campaign && offer && (
          <OfferCard offer={offer} campaign={campaign} onAccept={onAccept} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default NegotiationChat;
