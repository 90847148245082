import React from 'react'
import { 
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent
 } from "@ionic/react"
import { useHistory } from "react-router"
import EditProfileForm from "../../components/account/EditProfileForm"

const SignUpDetails:React.FC = () => {

    const history = useHistory()

    const redirectToHome = () => {
        history.push('/holding')
    }

    return (
        <IonPage>
            <IonHeader class="ion-no-border">
                <IonToolbar>
                    <IonTitle>Complete Your Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="mt-4 flex flex-col place-items-center place-content-center">
                    <EditProfileForm submitCallback={redirectToHome}/>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default SignUpDetails