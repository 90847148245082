import { Auth } from "@aws-amplify/auth";
import axios from "axios";
import { MutableRefObject, useCallback, useRef, useState, useContext } from "react";
import log from 'loglevel';
import ErrorContext, {ErrorContainer} from "../context/ErrorContext";

export type HTTPMethod = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export type Service = "brand" | "campaign" | "commit" | "offer" | "creator" | "notification" | "admin" | "financial" | "content";

export type AdminFunctions = "getPlatformStats" | "listToChaseCommits" |"listCreators" | "listAllCreators" |"listBrands" | "listCampaigns" | "assignCampaign" | "assignCreatorStatus" | "listCreatorsCompletedAssigned";
export type BrandFunctions = "create" | "list" | "edit" | "fetch";
export type OfferFunctions = "list-brand-pending";
export type CampaignFunctions = "create" | "list" | "creatorList" | "fetch" | "edit";
export type CommitFunctions = "list-commits" | "submit-content" | "redeem" | "edit" | "creator-edit";
export type CreatorFunctions = "fetch" | "me" | "edit";
export type NotificationFunctions = "fetch" | "create" | "list" | "markAsSeen";
export type FinancialFunctions = "create-session" | "create-portal-session";
export type ContentFunctions = "brand-upload-content" | "creator-upload-content" | "render-pre-signed-url" | "upload-pre-signed-url";

export type Path = BrandFunctions | OfferFunctions | CampaignFunctions | CommitFunctions | CreatorFunctions | AdminFunctions | FinancialFunctions | ContentFunctions | NotificationFunctions;
export type RequestData = { body?: any; queryParams?: any };

/**
 *
 * @param method The HTTP Method to use for the request
 * @param service The service that you wish to call
 * @param path The path of the function you wish to invoke
 * @param data An object containing either queryParameters or json
 * @returns isLoading, apiCall, error
 */
export const useApi = (
  method: HTTPMethod,
  service: Service,
  path: Path,
  ignoreErr?: boolean,
): [
    boolean,
    (data?: RequestData) => Promise<any>,
    MutableRefObject<Error | undefined>
  ] => {
  const [busy, setBusy] = useState<boolean>(false);
  const errorContext = useContext(ErrorContext)
  const error = useRef<Error>();

  const apiCall = useCallback(
    async (data: any) => {
      error.current = undefined;
      setBusy(true);
      log.info("Making API Call with Data: ", data);
      let res: any;
      try {
        if(ignoreErr == null || ignoreErr == undefined) ignoreErr = false;
        res = await callApi(method, service, path, ignoreErr, data);
      } catch (err) {
          errorContext.setError({errorObject: err, isApiError: true, fatal:true, message: err.message})
          error.current = err;
          throw err
      }
      setBusy(false);
      return res;
    },
    [method, service, path]
  );

  return [busy, apiCall, error];
};

/**
 *
 * @param method The HTTP Method to use for the request
 * @param service The service that you wish to call
 * @param path The path of the function you wish to invoke
 * @param data An object containing either queryParameters or json
 */
export const callApi = async (
  method: HTTPMethod,
  service: Service,
  path: Path,
  ignoreErr: boolean,
  data?: RequestData,
) => {
  const customHeaders: any = {
    "Content-Type": "application/json",
  };

  try {
    const authSession = await Auth.currentSession();
    const jwtToken = await authSession.getAccessToken().getJwtToken();
    customHeaders["Authorization"] = `Bearer ${jwtToken}`;
    log.info(jwtToken)
  } catch {
    log.error("Could not set token");
   }

  log.info("Sending API Request to ", service, "/", path);
  log.info("Headers:", customHeaders)

  try{
    const response = await axios({
      method,
      headers: { ...customHeaders },
      url: `${process.env.REACT_APP_API_BASE_URL}/${service}/${path}`,
      params: data?.queryParams,
      data: data?.body,
      responseType: "json",
    });
    if (response.status < 200 || response.status >= 300) {
       console.error(`[API_RESPONSE_ERROR] url:${service}/${path} status:${response.status} body:${JSON.stringify(response.data)}`)
       throw new Error(response.data?.message ? response.data.message:  `API Call failure ${service}/${path}:`+response.status);
    }
    return response.data;
  } catch(e) {
    if(!ignoreErr) {
        console.error(`[SP_API_FAILURE] ${JSON.stringify(e)}`, )
        throw e
    }else{
        console.info(`Ignoring error`, e)
        return null;
    }

  }
};


export const uploadImageS3 = async (
    path:string,
    contentType: string,
    data?: RequestData,
) => {
    const customHeaders: any = {
        "Content-Type": contentType,
    };

    log.info("Upload Image. Sending API Request to ", path);
    log.info("Headers:", customHeaders)

    try{
        const response = await axios({
            method: 'PUT',
            headers: { ...customHeaders },
            url: `${path}`,
            data: data?.body
        });
        if (response.status < 200 || response.status >= 300) {
            console.error(`[IMAGE_UPLOAD_ERROR] url:${path} status:${response.status} body:${JSON.stringify(response.data)}`)
            throw new Error(response.data?.message ? response.data.message:  `API Call failure ${path}:`+response.status);
        }
        return response.data;
    } catch(e) {
            console.error(`[SP_API_FAILURE] ${JSON.stringify(e)}`, )
            throw e
    }
};
