import { Auth } from "@aws-amplify/auth";
import {
  IonAlert,
  IonAvatar,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { arrowForward, book, logOut, pencilOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../context/UserContext";

const AccountTab: React.FC = () => {
  const [showSignoutDialog, setShowSignoutDialog] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setShowSignoutDialog(false);
  }, []);

  useIonViewWillEnter(() => {
    //todo is this needed?
  });

  let history = useHistory();

  const signOut = () => {
    Auth.signOut()
    window.location.href = '/auth/login'
  };

  const editProfileOnClick = () => {
    history.push("/home/account/edit-profile");
  };

  const profileOnClick = () => {
    history.push("/home/account/profile");
  };

  const tosOnClick = () => {
    window.location.href = "https://www.socialplug.co.uk/terms-of-use";
  };

  const privacyPolicyOnClick = () => {
    window.location.href = "https://www.socialplug.co.uk/privacy-policy";
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList className="mr-2 ">
          {user && (
            <IonItem onClick={() => profileOnClick()}>
              <IonAvatar slot="start">
                <img
                  src={user.profileImage.url}
                  alt="user avatar"
                  className="w-10"
                />
              </IonAvatar>
              <IonLabel>
                <IonText>{user.name}</IonText>
              </IonLabel>
              <IonIcon icon={arrowForward} slot="end" className="ml-10" />
            </IonItem>
          )}
          <IonItem onClick={() => editProfileOnClick()}>
            <IonIcon icon={pencilOutline} className="mr-2" />
            <IonLabel>
              <IonText>Edit Profile</IonText>
            </IonLabel>
            <IonIcon icon={arrowForward} />
          </IonItem>
          <IonItem onClick={() => tosOnClick()}>
            <IonIcon icon={book} className="mr-2" />
            <IonLabel>
              <IonText>Terms of use</IonText>
            </IonLabel>
            <IonIcon icon={arrowForward} />
          </IonItem>
          <IonItem onClick={() => privacyPolicyOnClick()}>
            <IonIcon icon={book} className="mr-2" />
            <IonLabel>
              <IonText>Privacy Policy</IonText>
            </IonLabel>
            <IonIcon icon={arrowForward} />
          </IonItem>
          <IonItem onClick={() => setShowSignoutDialog(true)}>
            <IonIcon icon={logOut} className="mr-2 fill-current text-red-500" />
            <IonLabel color="danger">
              <IonText>Sign out</IonText>
            </IonLabel>
            <IonIcon color="danger" icon={arrowForward} />
          </IonItem>
        </IonList>

        <IonAlert
          isOpen={showSignoutDialog}
          onDidDismiss={() => setShowSignoutDialog(false)}
          header={"Sign out"}
          message={"Do you wish to sign out?"}
          buttons={[
            {
              text: "No",
              role: "cancel",
              handler: () => {
                setShowSignoutDialog(false);
              },
            },
            {
              text: "Yes",
              handler: () => {
                signOut();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default AccountTab;
