import Auth from "@aws-amplify/auth";
import {
  IonActionSheet,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import { camera, imagesOutline, videocamOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import CommitListItem from "../../components/content/CommitListItem";

interface CommitListProps
  extends RouteComponentProps<{
    state: string;
  }> {}

const CommitList: React.FC<CommitListProps> = ({ match, history }) => {
  const [title, setTitle] = useState<String>("");
  const [commits, setCommits] = useState<Commit[]>();
  const [selectedCommit, setSelectedCommit] = useState<Commit>();
  const [showSubmitActionSheet, setShowSubmitActionSheet] = useState(false);
  const [, getCommits] = useApi("GET", "commit", "list");

  useEffect(() => {
    var state = "";
    switch (match.params.state) {
      case "awaitingApproval":
        setTitle("Awaiting Approval");
        state = "AwaitingApproval";
        break;
      case "declined":
        setTitle("Declined");
        state = "Declined";
        break;
      case "completed":
        setTitle("Completed");
        state = "Completed";
        break;
      case "awaitingSubmissionApproval":
        setTitle("Awaiting Approval");
        state = "AwaitingSubmissionApproval";
        break;
      case "awaitingSubmission":
        setTitle("Awaiting Content");
        state = "ContentPending";
        break;
    }
    try {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        const foundCommits: Commit[] = await getCommits({
          queryParams: { userId: user.username, state: state },
        });
        setCommits(foundCommits.reverse());
      })();
    } catch {}
  }, [match.params.state, getCommits]);

  const commitOnClick = (commit: Commit) => {
    setSelectedCommit(commit);
    switch (match.params.state) {
      case "awaitingSubmission":
        setShowSubmitActionSheet(true);
        break;
      case "awaitingApproval":
      case "awaitingSubmissionApproval":
        history.push(`/home/campaigns/view/${commit.campaignId}`);
        break;
      default:
        break;
    }
  };

  const redirectToSubmission = (type: "singlePost" | "carousel" | "video") => {
    if (type === "video" && selectedCommit) {
      setShowSubmitActionSheet(false);
      history.push(`/home/content/submitvideo/${selectedCommit.id}`);
    }
    if (selectedCommit && (type === "singlePost" || type === "carousel")) {
      setShowSubmitActionSheet(false);
      history.push(`/home/content/submit/${selectedCommit.id}/${type}`);
    }
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/home/content`} />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {commits?.map((commit) => (
            <div key={commit.id} onClick={() => commitOnClick(commit)}>
              <CommitListItem key={commit.id} commit={commit} />
            </div>
          ))}
        </IonList>
      </IonContent>

      <IonActionSheet
        isOpen={showSubmitActionSheet}
        onDidDismiss={() => setShowSubmitActionSheet(false)}
        buttons={[
          {
            text: "Single Post",
            icon: camera,
            handler: () => {
              redirectToSubmission("singlePost");
            },
          },
          {
            text: "Carousel Post",
            icon: imagesOutline,
            handler: () => {
              redirectToSubmission("carousel");
            },
          },
          {
            text: "Video",
            icon: videocamOutline,
            handler: () => {
              redirectToSubmission("video");
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default CommitList;
