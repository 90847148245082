import { IonIcon, IonItem, IonText } from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { logoUsd } from "ionicons/icons";
import React, { useEffect, useState } from "react";

const PaymentListItem: React.FC<any> = (props) => {
  const offer: Offer = props.offer;
  const [campaign, setCampagin] = useState<Campaign>();
  const [, getCampaign] = useApi("GET", "campaign", "fetch");

  useEffect(() => {
    (async () => {
      if (offer) {
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: offer.campaignId },
        });
        setCampagin(foundCampaign);
      }
    })();
  }, [offer, getCampaign]);

  return (
    <React.Fragment>
      <IonItem className="w-full">
        <IonIcon icon={logoUsd} className="fill-current w-1/4 " />
        <div className="flex flex-col">
          <IonText>
            {campaign?.brandName} have agreed to pay you £{offer.amount} for{" "}
            {" " + campaign?.title}
          </IonText>
          <IonText>
            Complete your post to instagram then send your invoice to{" "}
            {offer.brandEmail + " "}
            to get paid.
          </IonText>
        </div>
      </IonItem>
    </React.Fragment>
  );
};

export default PaymentListItem;
