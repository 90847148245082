import Auth from "@aws-amplify/auth";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import OfferLabel from "../../components/content/negotiation-chat/OfferLabel";

interface OffersProps
  extends RouteComponentProps<{
    state: string;
  }> {}

const Offers: React.FC<OffersProps> = ({ match, history }) => {
  const [offers, setOffers] = useState<Offer[]>();
  const [title, setTitle] = useState<String>("");
  const [, listOffers] = useApi("GET", "offer", "list");
  const [, getCommits] = useApi("GET", "commit", "list");

  useEffect(() => {
    try {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        var foundOffers: Offer[] = [];
        switch (match.params.state) {
          case "pending":
            setTitle("Pending Offers");
            foundOffers = await listOffers({
              queryParams: { toId: user.username, pending: true },
            });
            break;
          case "accepted":
            setTitle("Accepted Offers");
            foundOffers = await listOffers({
              queryParams: { toId: user.username, accepted: true },
            });
            break;
          case "waiting":
            setTitle("Sent Offers");
            const waitingCommits: Commit[] = await getCommits({
              queryParams: {
                accepted: true,
                offerAccepted: false,
              },
            });
            waitingCommits.forEach(async (commit) => {
              const waitingOffers: Offer[] = await listOffers({
                queryParams: {
                  commitId: commit.id,
                  fromId: user.username,
                  pending: true,
                },
              });
              foundOffers.concat(waitingOffers);
            });
            break;
        }
        setOffers(foundOffers.reverse());
      })();
    } catch {}
  }, [listOffers, getCommits, match.params.state]);

  const handleMessageOnClick = (offer: Offer) => {
    if (!offer.accepted && !offer.declined) {
      history.push(`/home/inbox/negotiate/${offer.id}`);
    }
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref="/home/content"
              className="fill-current "
            />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {offers?.map((offer) => (
            <OfferLabel
              offer={offer}
              key={offer.id}
              accepted={offer.accepted}
              onClick={() => handleMessageOnClick(offer)}
            />
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Offers;
