import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Route } from "react-router-dom";
import AccountTab from "../../pages/account-tab/AccountTab";
import EditProfile from "./EditProfile";
import Profile from "./Profile";

const AccountIndex: React.FC = () => {
  return (
    <React.Fragment>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/home/:tab(account)" exact component={AccountTab} />
          <Route
            path="/home/:tab(account)/edit-profile"
            exact
            component={EditProfile}
          />
          <Route path="/home/:tab(account)/profile" exact component={Profile} />
        </IonRouterOutlet>
      </IonReactRouter>
    </React.Fragment>
  );
};

export default AccountIndex;
