import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Route } from "react-router-dom";
import CampaignsTab from "../../pages/campaigns-tab/CampaignsTab";
import CampaignDetails from "./campaign-details/CampaignDetails";

const CampaignIndex: React.FC = () => {
  return (
    <React.Fragment>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/home/:tab(campaigns)" exact component={CampaignsTab} />
          <Route
            path="/home/:tab(campaigns)/view/:id"
            component={CampaignDetails}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </React.Fragment>
  );
};

export default CampaignIndex;
