import Auth from "@aws-amplify/auth";
import { IonAvatar, IonIcon, IonItem, IonText } from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { arrowForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const NegotiationListItem: React.FC<any> = (props) => {
  const offer: Offer = props.offer;
  const [loggedInUser, setLoggedInUser] = useState("");
  const [campaign, setCampaign] = useState<Campaign>();
  const [, getCampaign] = useApi("GET", "campaign", "fetch");

  let history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const userAuth = await Auth.currentAuthenticatedUser();
        setLoggedInUser(userAuth.username);
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: offer.campaignId },
        });
        setCampaign(foundCampaign);
      } catch {}
    })();
  }, [getCampaign, offer]);

  const redirectNegotiate = () => {
    history.push(`/home/content/offer/${offer.id}`);
  };

  return (
    <IonItem>
      {offer.offeredToId === loggedInUser && (
        <IonItem onClick={() => redirectNegotiate()}>
          <IonAvatar className="mr-2">
            <img src={campaign?.coverImage?.url} alt="user avatar" />
          </IonAvatar>
          <IonText>
            {campaign?.brandName} has sent you an offer for {campaign?.title}
          </IonText>
          <IonIcon icon={arrowForward} />
        </IonItem>
      )}
      {offer.offeredById === loggedInUser && (
        <IonItem>
          <IonAvatar className="mr-2">
            <img src={campaign?.coverImage?.url} alt="user avatar" />
          </IonAvatar>
          <IonText>
            Your price request of {offer.amount} has been sent to{" "}
            {campaign?.brandName} for approval
          </IonText>
        </IonItem>
      )}
    </IonItem>
  );
};

export default NegotiationListItem;
