import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { home } from "ionicons/icons";
import { useHistory } from "react-router-dom";
const ErrorMessage: React.FC = () => {
  let history = useHistory();

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle className="text-red-500">Error</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="flex flex-col ml-4 mt-10">
          <IonText className="text-red-500 text-xl">
            Uh-oh something went wrong.
          </IonText>
          <IonText className="text-red-500 text-lg">
            If this problem persists, please contact support at
            support@socialplug.co.uk
          </IonText>
          <IonButton
            fill="outline"
            style={{
              "--shadow": 0,
            }}
            className="w-80"
            onClick={() => history.push("/")}
          >
            <IonIcon icon={home} className="mr-2" />
            Go to homepage
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ErrorMessage;
