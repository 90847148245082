import { IonAvatar, IonItem, IonLabel } from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useEffect, useState } from "react";

const OfferLabel: React.FC<any> = (props) => {
  const offer: Offer = props.offer;

  const accepted: boolean = props.accepted;

  const [campaign, setCampaign] = useState<Campaign>();
  const [, getCampaign] = useApi("GET", "campaign", "fetch");

  useEffect(() => {
    (async () => {
      try {
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: offer.campaignId },
        });
        setCampaign(foundCampaign);
      } catch {}
    })();
  }, [getCampaign, offer]);

  return (
    <IonItem onClick={props.onClick} style={{ "--background": "#1F2937" }}>
      <IonAvatar slot="start">
        <img src="/assets/avatar_placeholder.png" alt="user avatar" />
      </IonAvatar>
      <IonLabel className="bg-gray-800">
        {accepted && (
          <h2 className="text-yellow-300">
            Accepted offer for: {campaign?.title}
          </h2>
        )}
        {!accepted && (
          <h2 className="text-yellow-300">New offer for: {campaign?.title}</h2>
        )}
        <h3 className="text-yellow-300">
          From {campaign?.brandName} for £{offer.amount}
        </h3>
      </IonLabel>
    </IonItem>
  );
};

export default OfferLabel;
