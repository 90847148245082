import { Auth } from "@aws-amplify/auth";
import {
  IonApp,
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import EnvironmentGraphic from "@socialplug/common/components/environment-graphic";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import { camera, heart, notifications, personCircle } from "ionicons/icons";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import AccountIndex from "./components/account/AccountIndex";
import CampaignIndex from "./components/campaigns/CampaignIndex";
import VerifyCampaignOffer from "./components/campaigns/verify-campaign/VerifyCampaignOffer";
import ContentIndex from "./components/content/ContentIndex";
import InboxIndex from "./components/inbox/InboxIndex";
import NotificationBadgeContext from "./context/NotificationContext";
import UserContext from "./context/UserContext";
import ErrorMessage from "./pages/ErrorMessage";
import Holding from "./pages/register/Holding";
import SignUpDetails from "./pages/register/SignUpDetails";
//sign in page
import SignIn from "./pages/sign-in/SignIn";
import SplashScreen from "./pages/splash-screen/SplashScreen";
/* TailwindCSS */
import "./theme/tailwind.css";
/* Theme variables */
import "./theme/variables.css";

const Routes = withRouter(({ authenticated }: any) => {
  const location = useLocation();
  console.log(location.pathname);
  const { user } = useContext(UserContext);

  const notificationContext = useContext(NotificationBadgeContext);
  const notificationBadgeValue = notificationContext.notificationBadgeValue;
  const [, getNotifications] = useApi("GET", "notification", "list");

  useEffect(() => {
    try {
      (async () => {
        if (user !== null && user !== undefined) {
          const foundNotifications: Notification[] = await getNotifications({
            queryParams: { userId: user.id, seen: false },
          });
          notificationContext.notificationBadgeValue =
            foundNotifications.length;
        }
      })();
    } catch {}
  }, [user]);

  return (
    <Switch>
      {!authenticated && (
        <>
          <Redirect to="/auth/login" from='/'/>
          <Route exact path="/auth/login" component={SignIn} />
        </>
      )}
      
      {authenticated && user && user.status === 'Incomplete' && (
        <>
          <Redirect to="/auth/complete" />
          <Route exact path="/auth/complete" component={SignUpDetails} />
        </>
      )}

      {user && ((user.status === "Waiting" && user.assignedCampaignId === '') || user.status === "Rejected") &&
        user.assignedCampaignId === "" && (
          <>
            <Redirect to="/holding" />
            <Route path="/holding" exact component={Holding} />
          </>
      )}

      {authenticated && location.pathname !== "/home/campaigns" && (
        <Redirect exact from="/" to={"/home/campaigns"} />
      )}

      <Route exact path="/error" component={ErrorMessage} />
      <Route exact path="/auth/complete" component={SignUpDetails} />
      <Route path="/verifyoffer" exact component={VerifyCampaignOffer} />

      {authenticated && (user?.status === 'Approved' || user?.assignedCampaignId !== '') && (
        <>
          <Redirect from="/" to="/home/campaigns" />
          <Route path="/home">
            <IonTabs>
              <IonRouterOutlet>
                <Route path="/home/:tab(campaigns)" component={CampaignIndex} />
                <Route path="/home/:tab(content)" component={ContentIndex} />
                <Route path="/home/:tab(inbox)" component={InboxIndex} />
                <Route path="/home/:tab(account)" component={AccountIndex} />
              </IonRouterOutlet>

              <IonTabBar slot="bottom">
                <IonTabButton tab="tab1" href="/home/campaigns">
                  <IonIcon icon={heart} />
                  <IonLabel>Campaign</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab2" href="/home/content">
                  <IonIcon icon={camera} />
                  <IonLabel>Posts</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab3" href="/home/inbox">
                  {notificationBadgeValue > 0 && (
                    <IonBadge color="danger">{notificationBadgeValue}</IonBadge>
                  )}
                  <IonIcon icon={notifications} />
                  <IonLabel>Notifications</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab4" href="/home/account">
                  <IonIcon icon={personCircle} />
                  <IonLabel>Account</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </Route>
        </>
      )}
      
    </Switch>
  );
});

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const contextProviderValue = { user, setUser };
  const [, fetchMe] = useApi("GET", "creator", "me", true);

  useEffect(() => {
    (async () => {
      try {
        const foundUser: User = await fetchMe();
        if (foundUser && foundUser.id !== null) {
          setUser(foundUser);
        } else {
          setUser(undefined);
        }
        console.log("User logged in:" + JSON.stringify(foundUser));
      } catch (err) {
        console.log("Error fetching me:" + err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const amplifyUser = await Auth.currentAuthenticatedUser();
        let authenticated = !!amplifyUser;
        setIsAuthenticated(authenticated);
        if (authenticated) {
          try {
            const foundUser: User = await fetchMe();
            if (foundUser && foundUser.id  !== null) {
              setUser(foundUser);
            } else {
              console.log(
                "Unexpected error, authenticated user should have /me available"
              );
              setUser(undefined);
            }
            console.log("User logged in:" + JSON.stringify(foundUser));
          } catch (err) {
            console.log("Error fetching me:" + err);
          }
        }
      } catch (err) {
        setIsAuthenticated(false);
        setUser(undefined);
        log.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <UserContext.Provider value={contextProviderValue}>
      <IonApp>
        <IonReactRouter>
          <EnvironmentGraphic />
          <IonRouterOutlet>
            <Routes authenticated={isAuthenticated} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </UserContext.Provider>
  );
};

export default App;
