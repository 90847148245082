import Auth from "@aws-amplify/auth";
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import Commit from "@socialplug/common/interfaces/commit";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { arrowForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const ContentTab: React.FC = () => {
  let history = useHistory();

  const [commits, setCommits] = useState<Commit[]>();
  const [offers, setOffers] = useState<Offer[]>();
  const [, listCommits] = useApi("GET", "commit", "list");
  const [, listOffers] = useApi("GET", "offer", "list");

  useEffect(() => {
    try {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        const foundCommits: Commit[] = await listCommits({
          queryParams: { userId: user.username },
        });
        setCommits(foundCommits);
        const foundOffersToUser: Offer[] = await listOffers({
          queryParams: { toId: user.username, pending: true },
        });
        const foundOffersFromUser: Offer[] = await listOffers({
          queryParams: { byId: user.username, pending: true },
        });
        const totalOffers = foundOffersFromUser.concat(foundOffersToUser);
        setOffers(totalOffers);
      })();
    } catch {}
  }, [listCommits, listOffers]);

  useIonViewWillEnter(() => {
    try {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        const foundCommits: Commit[] = await listCommits({
          queryParams: { userId: user.username },
        });
        setCommits(foundCommits);
        const foundOffersToUser: Offer[] = await listOffers({
          queryParams: { toId: user.username, pending: true },
        });
        const foundOffersFromUser: Offer[] = await listOffers({
          queryParams: { byId: user.username, pending: true },
        });
        const totalOffers = foundOffersFromUser.concat(foundOffersToUser);
        setOffers(totalOffers);
      })();
    } catch {}
  });

  const numberOfCommitsOfState = (state: string) => {
    var toReturn = 0;
    commits?.forEach((commit) => {
      if (commit.state === state) {
        toReturn++;
      }
    });
    return toReturn;
  };

  const redirectToSubmissions = (state: string) => {
    history.push(`/home/content/${state}`);
  };

  const redirectToNegotiations = () => {
    history.push(`/home/content/negotiations`);
  };

  const redirectToPayments = () => {
    history.push(`/home/content/payments`);
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle>Posts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonListHeader className="text-xl font-bold">Campaigns</IonListHeader>
          <IonItem onClick={() => redirectToSubmissions("awaitingApproval")}>
            <IonLabel>
              Awaiting Access ({numberOfCommitsOfState("AwaitingApproval")})
            </IonLabel>
            <IonIcon icon={arrowForward} slot="ml-10" />
          </IonItem>
          <IonItem onClick={() => redirectToSubmissions("declined")}>
            <IonLabel>Declined ({numberOfCommitsOfState("Declined")})</IonLabel>
            <IonIcon icon={arrowForward} slot="end" className="ml-10" />
          </IonItem>
          <IonItem onClick={() => redirectToSubmissions("completed")}>
            <IonLabel>
              Completed ({numberOfCommitsOfState("Completed")})
            </IonLabel>
            <IonIcon icon={arrowForward} slot="end" className="ml-10" />
          </IonItem>

          <IonListHeader className="text-xl font-bold">Content</IonListHeader>
          <IonItem onClick={() => redirectToSubmissions("awaitingSubmission")}>
            <IonLabel>
              Awaiting Content ({numberOfCommitsOfState("ContentPending")})
            </IonLabel>
            <IonIcon icon={arrowForward} slot="end" className="ml-10" />
          </IonItem>
          <IonItem
            onClick={() => redirectToSubmissions("awaitingSubmissionApproval")}
          >
            <IonLabel>
              Awaiting Approval (
              {numberOfCommitsOfState("AwaitingSubmissionApproval")})
            </IonLabel>
            <IonIcon icon={arrowForward} slot="end" className=" ml-10" />
          </IonItem>
          <IonItem onClick={() => redirectToNegotiations()}>
            <IonLabel>Cash Offers ({offers?.length})</IonLabel>
            <IonIcon icon={arrowForward} slot="end" className=" ml-10" />
          </IonItem>
          <IonItem onClick={() => redirectToPayments()}>
            <IonLabel>Rewards</IonLabel>
            <IonIcon icon={arrowForward} slot="end" className=" ml-10" />
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ContentTab;
