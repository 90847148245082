
import React, { useEffect } from "react"
import ImageUploadField from "../campaigns/campaign-submission/ImageUploadField"
import User from "@socialplug/common/interfaces/user"
import { useForm, useFieldArray, FieldArrayWithId } from "react-hook-form"
import { IonAvatar, IonIcon, IonText, useIonAlert } from "@ionic/react"
import { useApi } from "@socialplug/common/utilities/api"
import FormTextField from "../forms/FormTextField"
import { useContext } from "react"
import UserContext from "../../context/UserContext"
import { add, closeCircleOutline, imageOutline, personCircle, videocamOutline } from "ionicons/icons"
import LoadingButton from "../LoadingButton"
import FormTextArea from "../forms/FormTextArea"
import VideoPicker from "../campaigns/campaign-submission/VideoPicker"

export type EditProfileFormProps = {
    submitCallback? : () => void
}

const EditProfileForm:React.FC<EditProfileFormProps> = ({ submitCallback }) => {
    const [present] = useIonAlert()
    const userContext = useContext(UserContext)
    const user = userContext.user

    const [isSavingUser, saveUser, saveUserErr] = useApi(
        "POST",
        "creator",
        "edit"
    )
    const [, fetchMe] = useApi("GET", "creator", "me", true)

    const {
        handleSubmit,
        reset,
        watch,
        control,
        getValues,
        setValue,
        formState: { errors },
      } = useForm<User>()

    const profileImage = watch('profileImage')

    const imagePortfolioFields = useFieldArray({
        control,
        name: 'imagePortfolio',
    })

    const videoPortfolioFields = useFieldArray({
        control,
        name: 'videoPortfolio'
    })

    useEffect(() => {
        reset({
            ...user,
            imagePortfolio: user?.imagePortfolio ? user.imagePortfolio : [],
            videoPortfolio: user?.videoPortfolio ? user.videoPortfolio : [],
        })
      }, [reset, user])

    const onPortfolioImageClick = (field: FieldArrayWithId<User, 'imagePortfolio', 'id'>) => {
        if (imagePortfolioFields.fields.length > 0) {
            const index = imagePortfolioFields.fields.indexOf(field)
            if (index >= 0) {
                imagePortfolioFields.fields.splice(index, 1)
                imagePortfolioFields.remove(index)
            }
        }
    }

    const onPortfolioVideoClick = (field: FieldArrayWithId<User, 'videoPortfolio', 'id'>) => {
        if(videoPortfolioFields.fields.length > 0) {
            const index = videoPortfolioFields.fields.indexOf(field)
            if(index >= 0 ){
                videoPortfolioFields.fields.splice(index, 1)
                videoPortfolioFields.remove(index)
            }
        }
    }

    const onSubmit = async() => {
        const formValues = getValues()
        const {
            name,
            profileImage,
            bio,
            website,
            instagramAccountHandle,
            tiktokAccountHandle,
            followers,
            location,
            shippingAddress,
        } = formValues
        try {
            await saveUser({ 
                body: { 
                    name, 
                    profileImage,
                    imagePortfolio: imagePortfolioFields.fields,
                    videoPortfolio: videoPortfolioFields.fields,
                    bio,
                    website,
                    instagramAccountHandle: instagramAccountHandle.trim().replace('@', ''),
                    tiktokAccountHandle: tiktokAccountHandle.trim().replace('@', ''),
                    followers,
                    location,
                    shippingAddress
                }
            })
            if(saveUserErr.current) {
                throw new Error(saveUserErr.current.message)
            } else {
                present({
                    header: 'Your profile has been saved'
                })
                const updatedUser = await fetchMe()
                if(updatedUser) {
                    userContext.setUser(updatedUser)
                }
                if(submitCallback){
                    submitCallback()
                }
            }
        } catch (e) {
            console.error(e)
            present({
                header: 'Could not save details',
                subHeader: 'Something went wrong, please try again later'
            })
        }
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-center place-items-center place-content-center"
        >
            <ImageUploadField
                purpose='CreatorProfile'
                id='select-cover'
                onImageUploaded={(image) => {
                    setValue('profileImage', image)
                }}
            >
                <div className="flex flex-col place-content-center place-items-center">
                    {profileImage && (
                        <IonAvatar className='w-32 h-32'>
                            <img alt="avatar" src={profileImage?.url} />
                        </IonAvatar>
                    )}
                    {!profileImage && (
                        <IonIcon icon={personCircle} className='w-32 h-32'/>
                    )}
                    <IonText className="text-center">
                        Click to change profile image
                    </IonText>
                </div>
            </ImageUploadField>

            <FormTextField
                name="name"
                control={control}
                required
                error={errors.name}
                label="Your Name*"
            />

            <FormTextField
                name="instagramAccountHandle"
                control={control}
                required
                error={errors.instagramAccountHandle}
                label="Your Instagram Account Handle*"
            />

            <FormTextField
                name="tiktokAccountHandle"
                control={control}
                required
                error={errors.tiktokAccountHandle}
                label="Your Tiktok Account Handle*"
            />

            <FormTextField
                name="followers"
                control={control}
                required
                error={errors.followers}
                type='number'
                label="How many followers in total do you have?*"
            />

            <FormTextArea
                name="bio"
                control={control}
                error={errors.bio}
                required
                label="Bio*"
            />

            <FormTextField
                name="location"
                control={control}
                required
                error={errors.location} 
                label='Where you are based*'
            />

            <FormTextArea
                name="shippingAddress"
                control={control}
                required
                error={errors.shippingAddress}
                label='Your shipping address and Postcode*'
            />

            <FormTextField
                name="website"
                control={control}
                error={errors.website}
                label="Link to your online portfolio"
            />
            
            <IonText className="text-lg my-2">Image Portfolio:</IonText>
            <div className={`${imagePortfolioFields.fields.length > 0 ? 'grid grid-cols-2' : ''}`}>
                {imagePortfolioFields.fields.map((field) => (
                    <React.Fragment>
                        <img
                            alt={field.id}
                            key={field.id}
                            src={field.url}
                            className="w-28 m-2"
                            onClick={() => onPortfolioImageClick(field)}
                        />
                    </React.Fragment> 
                ))}
                {imagePortfolioFields.fields.length < 6 && (
                    <ImageUploadField
                        purpose='CreatorPortfolio'
                        id='select-cover'
                        onImageUploaded={(image) => {
                            imagePortfolioFields.append(image)
                        }}
                    >
                        <div className="flex flex-row bg-gray-400 place-content-center place-items-center w-28 h-28 m-2">
                            <IonIcon icon={add} className='w-8 h-8 fill-current text-white'/>
                            <IonIcon icon={imageOutline} className='w-16 h-16 fill-current text-white'/>
                        </div>
                    </ImageUploadField>
                )}
            </div>

            <IonText className="text-lg my-2">Video Portfolio:</IonText>
            {videoPortfolioFields.fields && videoPortfolioFields.fields.map((field) => (
                <div className='flex flex-col place-content-center place-items-center relative'>
                    <IonIcon
                        icon={closeCircleOutline}
                        className='w-6 h-6 fill-current text-white absolute top-0 right-0 mr-10 mt-5 lg:mr-16 z-10'
                        onClick={() => onPortfolioVideoClick(field)}
                    />
                    <video 
                        className="w-5/6 m-2 h-full"
                        controls
                        key={field.id}
                    >
                        <source src={field.url} type="video/mp4" />
                    </video>
                </div>
            ))}
            {videoPortfolioFields.fields.length < 6 && (
                <VideoPicker
                    onVideoUploaded={(video) => {
                        videoPortfolioFields.append(video)
                    }}
                    purpose='CreatorPortfolio'
                    id="select-video"
                >
                    <div className="flex flex-row bg-gray-400 place-content-center place-items-center w-28 h-28 m-2">
                        <IonIcon icon={add} className='w-8 h-8 fill-current text-white'/>
                        <IonIcon icon={videocamOutline} className='w-16 h-16 fill-current text-white'/>
                    </div>
                </VideoPicker>  
            )}
            
            <LoadingButton
                className="mt-8 mb-2 w-40 rounded-xl"
                loading={isSavingUser}
                disabled={
                    !profileImage
                    || imagePortfolioFields.fields.length === 0
                    || videoPortfolioFields.fields.length === 0
                }
                label="Save Changes"
                type='submit'
            />
        </form>
    )
}

export default EditProfileForm