import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Route } from "react-router-dom";
import CommitList from "../../pages/content-tab/CommitList";
import ContentTab from "../../pages/content-tab/ContentTab";
import Offers from "../../pages/content-tab/Offers";
import Payments from "../account/Payments";
import CampaignDetails from "../campaigns/campaign-details/CampaignDetails";
import CampaignSubmission from "../campaigns/campaign-submission/CampaignSubmission";
import VideoSubmission from "../campaigns/campaign-submission/VideoSubmission";
import NegotiationChat from "./negotiation-chat/NegotiationChat";
import NegotiationList from "./negotiation-chat/NeogitaitonList";

const ContentIndex: React.FC = () => {
  return (
    <React.Fragment>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/home/:tab(content)" exact component={ContentTab} />

          <Route
            path="/home/:tab(content)/:state"
            exact
            component={CommitList}
          />
          <Route
            path="/home/:tab(content)/offers/:state"
            exact
            component={Offers}
          />
          <Route
            path="/home/:tab(content)/submitVideo/:id"
            exact
            component={VideoSubmission}
          />
          <Route
            path="/home/:tab(content)/submit/:id/:type"
            exact
            component={CampaignSubmission}
          />
          <Route
            path="/home/:tab(content)/negotiations"
            exact
            component={NegotiationList}
          />
          <Route
            path="/home/:tab(content)/payments"
            exact
            component={Payments}
          />
          <Route
            path="/home/:tab(content)/offer/:id"
            exact
            component={NegotiationChat}
          />
          <Route
            path="/home/campaigns/view/:id"
            exact
            component={CampaignDetails}
          />
        </IonRouterOutlet>
      </IonReactRouter>
    </React.Fragment>
  );
};

export default ContentIndex;
