import { useIonAlert } from "@ionic/react";
import { useRef } from "react";
import {uploadImageS3, useApi} from "../../../../../shared/utilities/api";
import { ImageOutput } from "./ImageUploadField";

type VideoUploadFieldProps = {
  id: string;
  purpose: string;
  onVideoUploaded: (image: ImageOutput) => void;
  children: any;
  className?: any;
  campaignId?: string; 
}

const VideoPicker: React.FC<VideoUploadFieldProps> = ({
  id,
  campaignId,
  onVideoUploaded,
  purpose,
  children,
  className,
}) => {
  const [alert] = useIonAlert();
  const selectFileRef = useRef<any>(null);

  const [, getUploadUrl] = useApi(
      "POST",
      "content",
      "upload-pre-signed-url"
  );

  const [, getRenderUrl] = useApi(
      "POST",
      "content",
      "render-pre-signed-url"
  );

  const onChangeFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const fileSelected = event.target?.files[0];

    const fileSize = fileSelected.size / 1024 / 1024; //size in mb
    const videoLimitMb = 150
    if (fileSize >= videoLimitMb) {
      alert(`Video size exceeds limit of ${videoLimitMb}mb`);
    } else {
      const { url, id } = await getUploadUrl({
        body: { 
          filename: fileSelected.name,
          purpose,
          ...purpose === 'CampaignCommit' ? {campaignId:campaignId} : {},
          contentType:fileSelected.type
        },
      });

      console.log(`Uploading file:${id}, url:${url}, type:${fileSelected.type}`);
      await uploadImageS3(url, fileSelected.type, {body:fileSelected})
      const renderUrl =  await getRenderUrl({body:{key:id}})
      onVideoUploaded({ id, url: renderUrl.signedUrl });

      selectFileRef.current.value = null;
    }
  };

  return (
    <div>
      <input
        id={id}
        type={"file"}
        accept="video/mp4,video/quicktime"
        ref={selectFileRef}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
      <div
        className="cursor-pointer w-full h-full"
        onClick={() => {
          selectFileRef?.current
            ? selectFileRef.current.click()
            : console.log("no ref");
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default VideoPicker;
