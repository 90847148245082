import { IonIcon } from "@ionic/react";
import { refreshOutline } from "ionicons/icons";

const SplashScreen: React.FC = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <IonIcon
        className="text-4xl animate-spin"
        icon={refreshOutline}
      ></IonIcon>
    </div>
  );
};

export default SplashScreen;
