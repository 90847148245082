import log from "loglevel"

const AmplifyConfigStaging = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:1b1b7966-3416-41df-b531-795d89d24afd",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Zumi21ie4",
    "aws_user_pools_web_client_id": "3gv0ejsi1c9kp2e38v1up10h0n",
    "oauth": {
        "domain": "socialplug-user-pool-domain-staging-splug.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
        ],
        "redirectSignIn": "http://localhost:8100",
        "redirectSignOut": "http://localhost:8100",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};

const AmplifyConfigDev = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4350af5b-c8e7-4429-9888-4e58f13eaf4b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_0E31TYJs3",
    "aws_user_pools_web_client_id": "4j55a8k36lf848t7k70lqvdnjv",
    "oauth": {
        "domain": "socialplug-user-pool-domain-dev-splug.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
        ],
        "redirectSignIn": "http://localhost:8100",
        "redirectSignOut": "http://localhost:8100",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}

const AmplifyConfigProduction = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5e553c5b-3c71-4357-99c9-5142c7a14732",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_aXecgxU8K",
    "aws_user_pools_web_client_id": "7ao8sl9smr060up9gvbv7s5em9",
    "oauth": {
        "domain": "socialplug-user-pool-domain-prod-splug.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
        ],
        "redirectSignIn": "http://localhost:8100",
        "redirectSignOut": "http://localhost:8100",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}

const AmplifyConfigPreProduction = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:bf82e87f-2998-4e37-b60b-a1d36f5d081e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_U7W6dWESl",
    "aws_user_pools_web_client_id": "5536j23kv3g1jfhpjtg00cg2lf",
    "oauth": {
        "domain": "socialplug-user-pool-domain-preprod-splug.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
        ],
        "redirectSignIn": "http://localhost:8100",
        "redirectSignOut": "http://localhost:8100",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
}

export const getAmplifyConfigForEnv = () => {
    let whichConfig = AmplifyConfigDev;

    switch (process.env.REACT_APP_ENV) {
        case 'development': {
            log.info('Amplify config: development')
            whichConfig = AmplifyConfigDev;
        }
            break;
        case 'staging': {
            log.info('Amplify config: staging')
            whichConfig = AmplifyConfigStaging;
        }
            break;
        case 'production': {
            log.info('Amplify config: production')
            whichConfig = AmplifyConfigProduction;
        }
            break;
        case 'preproduction': {
            log.info('Amplify config: preproduction')
            whichConfig = AmplifyConfigPreProduction;
        }
            break;
    }

    const { location } = window;
    const url =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");

    whichConfig.oauth.redirectSignIn = url;
    whichConfig.oauth.redirectSignOut = url;

    return whichConfig
}