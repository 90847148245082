import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from "@ionic/react";
import { notifications, trashBin } from "ionicons/icons";
import React from "react";

const NotificationLabel: React.FC<any> = (props) => {
  return (
    <React.Fragment>
      <IonItemSliding>
        <IonItem>
          <IonIcon
            icon={notifications}
            size="large"
            slot="start"
            className="w-1/12"
          />
          <div className="flex flex-col">
            <h2 className="font-bold">{props.title}</h2>
            <h3>{props.description}</h3>
            <h4 className="text-sm">
              {new Date(props.created).toLocaleDateString()}
            </h4>
          </div>
        </IonItem>
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={props.onClick}>
            <IonIcon icon={trashBin} size="large" />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </React.Fragment>
  );
};

export default NotificationLabel;
