import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import { arrowBack, cameraOutline } from "ionicons/icons";
import logger from "loglevel";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import ErrorMessage from "../../../pages/ErrorMessage";
import FormTextArea from "../../forms/FormTextArea";
import FormTextField from "../../forms/FormTextField";
import ImageSelectionGrid from "./ImageSelectionGrid";
import ImageUploadField, { ImageOutput } from "./ImageUploadField";

type ContentSubmission = {
  postDescription: string;
  commentsToBrand: string;
  images: ImageOutput[];
};

interface CampaignDetailsProps
  extends RouteComponentProps<{
    id: string;
    type: string;
  }> {}

const CampaignSubmission: React.FC<CampaignDetailsProps> = ({
  match,
  history,
}) => {
  const [presentAlert] = useIonAlert()
  const [user, setUser] = useState<User>()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedCommit, setSelectedCommit] = useState<Commit>()
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm<ContentSubmission>({
    defaultValues: {
      commentsToBrand: "",
      postDescription: "",
      images: [],
    },
  });

  const images = watch("images")
  const description = watch('postDescription')

  const [loading, setLoading] = useState<boolean>(false)

  const [present, dismiss] = useIonToast();

  const [, getCampaign, getCampaignErr] = useApi("GET", "campaign", "fetch");
  const [, getCommit, getCommitErr] = useApi("GET", "commit", "fetch");
  const [, fetchMe, fetchMeErr] = useApi("GET", "creator", "me");
  const [isSubmittingContent, submitContent, submitContentErr] = useApi(
    "POST",
    "commit",
    "submit-content"
  );

  const { id, type } = match.params

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const foundCommit: Commit = await getCommit({
          queryParams: { commitId: id },
        })
        if (foundCommit) setSelectedCommit(foundCommit)
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: foundCommit.campaignId },
        })
        setSelectedCampaign(foundCampaign)
        const foundUser: User = await fetchMe()
        setUser(foundUser)
      } catch {}
      setLoading(false)
    })();
  }, [id, getCampaign, getCommit, fetchMe])

  useEffect(() => {
    if (getCampaignErr.current || getCommitErr.current || fetchMeErr.current) {
      presentAlert("Failed to load details")
    }
  }, [getCampaignErr, getCommitErr, fetchMeErr, presentAlert])

  const onConfirmSubmission = async () => {
    const formValues = getValues()

    const images = formValues.images
    const body = { ...formValues, images, commitId: id }

    try {
      await submitContent({ body })
    } catch (e) {
      logger.info("failed to submit content", e)
    }

    if (submitContentErr.current) {
      presentAlert("Failed to Submit Content")
    } else {
      setShowConfirmModal(false)
      redirectToContent()
    }
  }

  const redirectToContent = () => {
    window.location.href = "/home/content"
  }

  const getBrandHandle = () => {
    if (selectedCampaign?.atHandle.startsWith("@")) {
      return selectedCampaign.atHandle
    } else {
      return `@${selectedCampaign?.atHandle}`
    }
  }

  const insertHashtag = (tag: string) => {
    setValue("postDescription", `${getValues('postDescription')} ${tag} `)
  }

  const insertBrandHandle = (handle: string) => {
    if (selectedCampaign?.atHandle.startsWith("@")) {
      setValue('postDescription', `${getValues('postDescription')} ${handle} `)
    } else {
      setValue('postDescription', `${getValues('postDescription')} @${handle} `)
    }
  }

  if (loading) {
    return <IonLoading isOpen={true} />
  }

  const submitButtonDisabled = () => {
    var toReturn = false
    var descriptionWithSpace = description + " "
    if (selectedCampaign) {
      if (!images || images.length === 0) {
        toReturn = true
      }
      toReturn = !descriptionWithSpace.includes(`${selectedCampaign?.atHandle + " "}`)
      selectedCampaign.hashtags.forEach((hashtag) => {
        if (!descriptionWithSpace.includes(hashtag + " ")) {
          toReturn = true
        }
      })
    }
    return toReturn
  }

  const copyToClipboardOnClick = () => {
    present({
      buttons: [{ text: "hide", handler: () => dismiss() }],
      message: "post caption copied to clipboard",
    })
    navigator.clipboard.writeText(description)
  }

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`home/campaigns/view/${selectedCampaign?.id}`}
            />
          </IonButtons>
          <IonTitle>
            {selectedCampaign?.brandName} - {selectedCampaign?.title} Submission
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {selectedCampaign && (
          <React.Fragment>
            <div className="mt-2 ml-4 flex items-center">
              <IonAvatar className="w-6 h-6">
                <img src={user?.profileImage?.url} alt="user avatar" />
              </IonAvatar>
              <p className="ml-2">{user?.name}</p>
            </div>

            <form
              onSubmit={handleSubmit(() => setShowConfirmModal(true))}
              className="flex flex-col items-center justify-center w-full px-4"
            >
              {type === "singlePost" && (
                <div
                  className="w-full h-0 mt-4 relative"
                  style={{ paddingTop: "100%" }}
                >
                  <ImageUploadField
                    purpose="CampaignCommit"
                    campaignId={selectedCampaign?.id}
                    className="absolute top-0 w-full h-full"
                    id="select-cover"
                    onImageUploaded={(image: any) => {
                      setValue("images", [image]);
                    }}
                  >
                    <div className="flex flex-col bg-white h-full place-items-center place-content-center">
                      {images.length === 0 && (
                        <React.Fragment>
                          <IonIcon size="large" icon={cameraOutline} />
                          <p>Add Image</p>
                        </React.Fragment>
                      )}
                      {images.length !== 0 && (
                        <img alt="" src={images[0].url} />
                      )}
                    </div>
                  </ImageUploadField>
                </div>
              )}

              {type === "carousel" && (
                <ImageSelectionGrid
                  campaignId={selectedCampaign?.id}
                  className="mt-8"
                  name="images"
                  control={control}
                />
              )}

              {selectedCommit && selectedCommit.revisionRequested && (
                <div>
                  <p className="text-red-500">
                    Reason for resubmit: {selectedCommit.reasonForDecline}
                  </p>
                </div>
              )}

              <div className="w-full mt-8 text-sm ">
                <p className="font-bold">Must Include In Post Caption:</p>
                <p className="mb-1">
                  Our Username:{" "}
                  <span
                    onClick={() => insertBrandHandle(selectedCampaign.atHandle)}
                    className=" font-bold"
                  >
                    {getBrandHandle()}
                  </span>
                </p>
                <p className="mb-1 ">
                  Our Hashtags:{" "}
                  <div className="flex  font-bold">
                    {selectedCampaign.hashtags.map((tag) => (
                      <div className="mr-2" onClick={() => insertHashtag(tag)}>
                        {tag}
                      </div>
                    ))}
                  </div>
                </p>
              </div>

              <IonItem
                className="w-96 max-w-full mt-4"
                style={{
                  "--padding-start": "0px",
                }}
              >
                  <FormTextArea
                    name="postDescription"
                    control={control}
                    required
                    error={errors.postDescription}
                    label="Instagram Caption"
                    placeholder="Refer to the content direction and include our username and hashtags to continue"
                  />
              </IonItem>

              <IonItem
                className="w-96 max-w-full mt-4"
                style={{
                  "--padding-start": "0px",
                }}
              >
                  <FormTextArea
                    name="commentsToBrand"
                    control={control}
                    error={errors.commentsToBrand}
                    label="Comments to brand"
                    placeholder="Give some private feedback of your experience?"
                  />
              </IonItem>
              
              <div className="flex flex-col">
                <IonButton
                  className=" mt-8"
                  fill="outline"
                  style={{
                    "--shadow": 0,
                  }}
                  onClick={() => copyToClipboardOnClick()}
                >
                  Copy caption
                </IonButton>
              </div>

              <div className="flex flex-col">
                <IonButton
                  className=" mt-8"
                  fill="outline"
                  style={{
                    "--shadow": 0,
                  }}
                  disabled={isSubmittingContent || submitButtonDisabled()}
                  onClick={() => setShowConfirmModal(true)}
                >
                  Submit
                </IonButton>
              </div>
            </form>

            <IonModal isOpen={showConfirmModal}>
              <IonContent fullscreen>
                <IonHeader>
                  <IonToolbar>
                    <IonButton
                      slot="start"
                      fill="clear"
                      size="large"
                      onClick={() => setShowConfirmModal(false)}
                    >
                      <IonIcon
                        icon={arrowBack}
                        className="fill-current "
                      />
                    </IonButton>
                    <IonTitle>
                      {selectedCampaign.brandName} Guidelines
                    </IonTitle>
                  </IonToolbar>
                </IonHeader>
                <div className="flex flex-col justify-center text-lg ml-4 mr-4 mt-4 place-content-center place-items-center">
                  <IonLabel className="place-items-center text-lg place-content-center text-center">
                    Check you’ve met these content guidelines, otherwise
                    approval is unlikely
                  </IonLabel>
                  <IonLabel className="font-bold mt-10">
                    Content Requirements:
                  </IonLabel>
                  {selectedCampaign.rules && (
                    <React.Fragment>
                      {selectedCampaign.rules.map((rule) => (
                        <IonText
                          key={rule} 
                          className="text-center"
                        >
                          {rule}
                        </IonText>
                      ))}
                    </React.Fragment>
                  )}
                  {selectedCampaign.captionRules && (
                    <React.Fragment>
                      <IonText className="font-bold">
                        Caption Requirements:
                      </IonText>
                      {selectedCampaign.captionRules.map((captionRules) => (
                        <IonText
                          key={captionRules}
                          className="text-center"
                        >
                          {captionRules}
                        </IonText>
                      ))}
                    </React.Fragment>
                  )}
                  <br />
                  <IonButton
                    onClick={() => onConfirmSubmission()}
                    fill="outline"
                    disabled={isSubmittingContent}
                  >
                    Confirm Submission
                  </IonButton>
                </div>
              </IonContent>
            </IonModal>
          </React.Fragment>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CampaignSubmission;
