import { IonCol, IonItem, IonLabel, IonRow, IonText, IonTextarea } from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";

const FormTextArea: React.FC<any> = ({
  label,
  control,
  name,
  defaultValue,
  className,
  placeholder,
  required
}) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value } }) => (
        <IonItem className="w-96 mt-8 flex flex-col">
          <IonLabel position="stacked">
            <IonText className="block">{label}</IonText>
          </IonLabel>
          <IonTextarea
            style={{ marginTop: 0, "--padding-top": 0, '--padding-start': 4 }}
            placeholder={placeholder}
            value={value}
            className={`${className} `}
            onIonChange={onChange}
            onIonBlur={onBlur}
            required={required || false}
          />
        </IonItem>
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
    />
  );
};

export default FormTextArea;
