import Auth from "@aws-amplify/auth";
import {
  IonContent,
  IonHeader,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Notification from "@socialplug/common/interfaces/notification";
import { useApi } from "@socialplug/common/utilities/api";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import NotificationLabel from "../../components/inbox/NotificationLabel";
import NotificationBadgeContext from "../../context/NotificationContext";

const InboxTab: React.FC<RouteComponentProps> = ({ history }) => {
  const [notifications, setNotifications] = useState<Notification[]>();
  const [, getNotifications] = useApi("GET", "notification", "list");
  const [, editNotification] = useApi("POST", "notification", "markAsSeen");
  const notificationContext = useContext(NotificationBadgeContext);

  useEffect(() => {
    try {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        const foundNotifications: Notification[] = await getNotifications({
          queryParams: { userId: user.username, seen: false },
        });
        setNotifications(foundNotifications.reverse());
      })();
    } catch {}
  }, [getNotifications]);

  const notificationDeleteOnClick = (notification: Notification) => {
    (async () => {
      await editNotification({
        body: { notificationId: notification.id },
      });
    })();
    if (notifications !== undefined && notifications !== null) {
      var array: Notification[] = notifications;
      array = array.filter(function (item) {
        return item !== notification;
      });
      setNotifications(array);
      notificationContext.notificationBadgeValue =
        notificationContext.notificationBadgeValue - 1;
    }
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonTitle>Notifications</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {notifications?.map((notification) => {
            return (
              <NotificationLabel
                title={notification.title}
                description={notification.description}
                key={notification.id}
                created={notification.created}
                onClick={() => notificationDeleteOnClick(notification)}
              />
            );
          })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default InboxTab;
