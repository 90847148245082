import * as React from 'react'
import User from "../../../shared/interfaces/user";
export interface UserContainer {
    user: User|undefined,
    setUser: (user: User|undefined) => void
}
const UserContext = React.createContext<UserContainer>({user:undefined, setUser: ()=>{}})

export default UserContext

