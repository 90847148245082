import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import EditProfileForm from "./EditProfileForm";

const EditProfile = () => {
  let history = useHistory();

  const returnToAccountSettings = () => {
    history.push("/home/account");
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              className="fill-current "
              defaultHref="/home/account"
            />
          </IonButtons>
          <IonTitle>Edit Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="flex flex-col mt-2 place-items-center place-content-center">
          <EditProfileForm/>
          <IonButton fill="outline" onClick={() => returnToAccountSettings()}>
            <IonIcon icon={arrowBack} className="mr-2" />
            Back
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
