import { IonCard, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";

const CampaignCard: React.FC<any> = (props) => {
  const campaign: Campaign = props.campaign;

  const getRules = () => {
    var toReturn = "";
    if (campaign.rules) {
      campaign.rules.forEach((rule) => {
        campaign.rules.indexOf(rule) + 1 === campaign.rules.length
          ? (toReturn += rule)
          : (toReturn += rule + ", ");
      });
    }
    return toReturn;
  };

  const getRestrictions = () => {
    var toReturn = "";
    if (campaign.captionRules !== null) {
      campaign.captionRules.forEach((captionRules) => {
        campaign.captionRules.indexOf(captionRules) + 1 ===
        campaign.captionRules.length
          ? (toReturn += captionRules)
          : (toReturn += captionRules + ", ");
      });
    }

    return toReturn;
  };

  return (
    <IonCard
      className="bg-white w-5/6  flex flex-col place-items-center place-content-center text-center lg:w-1/4 "
      onClick={props.onClick}
    >
      <img src={campaign.coverImage.url} alt="campaign cover" />

      <div id="details" onClick={props.onClick} className="p-4 pt-3 ">
        <IonCardTitle className="text-black">
          {campaign.brandName} - {campaign.title}
        </IonCardTitle>
        <IonCardSubtitle className=" mt-1">
          {campaign.description}
        </IonCardSubtitle>
        <IonCardSubtitle className=" mt-1">
          Reward: {campaign.rewardType} - {campaign.rewardDescription}
        </IonCardSubtitle>
      </div>
    </IonCard>
  );
};

export default CampaignCard;
