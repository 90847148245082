import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import User from "@socialplug/common/interfaces/user";
import { useApi } from "@socialplug/common/utilities/api";
import { arrowBack, cameraOutline } from "ionicons/icons";
import logger from "loglevel";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import FormTextArea from "../../forms/FormTextArea";
import { ImageOutput } from "./ImageUploadField";
import VideoPicker from "./VideoPicker";

type ContentSubmission = {
  postDescription: string;
  commentsToBrand: string;
  video: ImageOutput;
};

interface VideoSubmissionProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const VideoSubmission: React.FC<VideoSubmissionProps> = ({
  match,
  history,
}) => {
  const { id } = match.params;
  const [description, setDescription] = useState<string>("");
  const [intialLoading, setLoading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [user, setUser] = useState<User>();
  const [campaign, setCampaign] = useState<Campaign>();

  const [present, dismiss] = useIonToast();

  const [, getCampaign, getCampaignErr] = useApi("GET", "campaign", "fetch");
  const [, getCommit, getCommitErr] = useApi("GET", "commit", "fetch");
  const [, fetchMe, fetchMeErr] = useApi("GET", "creator", "me");
  const [submittingContent, submitContent, submitContentErr] = useApi(
    "POST",
    "commit",
    "submit-content"
  );

  const [presentAlert] = useIonAlert();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    // formState: { errors },
  } = useForm<ContentSubmission>({
    defaultValues: {
      commentsToBrand: "",
      postDescription: "",
      video: undefined,
    },
  });

  const video = watch("video");

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const foundCommit: Commit = await getCommit({
          queryParams: { commitId: id },
        });
        const foundCampaign: Campaign = await getCampaign({
          queryParams: { campaignId: foundCommit.campaignId },
        });
        setCampaign(foundCampaign);
        const foundUser: User = await fetchMe();
        setUser(foundUser);
      } catch {}

      setLoading(false);
    })();
  }, [id, getCampaign, getCommit, fetchMe]);

  useEffect(() => {
    if (getCampaignErr.current || getCommitErr.current || fetchMeErr.current) {
      presentAlert("Failed to load details");
    }
  }, [getCampaignErr, getCommitErr, fetchMeErr, presentAlert]);

  const insertHashtag = (tag: string) => {
    setDescription(description + " " + tag + " ");
  };

  const insertBrandHandle = (handle: string) => {
    if (campaign?.atHandle.startsWith("@")) {
      setDescription(description + " " + handle + " ");
    } else {
      setDescription(description + " @" + handle + " ");
    }
  };

  if (intialLoading) {
    return <IonLoading isOpen={true} />;
  }

  const submitButtonDisabled = () => {
    var toReturn = false;
    var descriptionWithSpace = description + " ";
    if (campaign) {
      if (!video) {
        toReturn = true;
      }
      if (!descriptionWithSpace.includes(`${campaign?.atHandle + " "}`)) {
        toReturn = true;
      }
      campaign.hashtags.forEach((hashtag) => {
        if (!descriptionWithSpace.includes(hashtag + " ")) {
          toReturn = true;
        }
      });
    }
    return toReturn;
  };

  const redirectToContent = () => {
    window.location.href = "/home/content";
  };

  const onConfirmSubmission = async () => {
    const formValues = getValues();
    formValues.postDescription = description;

    const body = { ...formValues, videos: [video], commitId: id };

    try {
      await submitContent({ body });
    } catch (e) {
      logger.info("failed to submit content", e);
    }

    if (submitContentErr.current) {
      presentAlert("Failed to Submit Content");
    } else {
      setShowConfirmModal(false);
      redirectToContent();
    }
  };

  const copyToClipboardOnClick = () => {
    present({
      buttons: [{ text: "hide", handler: () => dismiss() }],
      message: "post caption copied to clipboard",
      onDidDismiss: () => console.log("dismissed"),
      onWillDismiss: () => console.log("will dismiss"),
    });
    navigator.clipboard.writeText(description);
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`home/campaigns/view/${campaign?.id}`}
            />
          </IonButtons>
          <IonTitle>
            {campaign?.brandName} - {campaign?.title} Submission
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {campaign && (
          <React.Fragment>
            <div className="mt-2 ml-4 flex items-center">
              <IonAvatar className="w-6 h-6">
                <img src={user?.profileImage?.url} alt="user avatar" />
              </IonAvatar>
              <p className=" ml-2">{user?.name}</p>
            </div>

            <form
              onSubmit={handleSubmit(() => setShowConfirmModal(true))}
              className="flex flex-col items-center justify-center w-full px-4"
            >
              <div className="w-full h-50 mt-4 relative">
                <VideoPicker
                  className="absolute top-0 w-full h-full"
                  id="select-video"
                  purpose="CampaignCommit"
                  campaignId={campaign?.id}
                  onVideoUploaded={(video: any) => {
                    setValue("video", video);
                    console.log(JSON.stringify(video));
                  }}
                >
                  <div className="flex flex-col bg-white h-40 place-items-center place-content-center">
                    {!video && (
                      <React.Fragment>
                        <IonIcon size="large" icon={cameraOutline} />
                        <p>Add Video</p>
                      </React.Fragment>
                    )}
                    {video && (
                      <video className="h-full w-full" controls>
                        <source src={video.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </VideoPicker>
              </div>
              <div className="w-full mt-8 text-sm ">
                <p className="font-bold">Must Include In Post caption:</p>
                <p className="mb-1">
                  Our Username:{" "}
                  <span
                    onClick={() => insertBrandHandle(campaign.atHandle)}
                    className="font-bold"
                  >
                    {campaign?.atHandle}
                  </span>
                </p>
                <div className="mb-1 ">
                  Our Hashtags:{" "}
                  <div className="flex font-bold">
                    {campaign.hashtags.map((tag) => (
                      <div
                        key={tag}
                        className="mr-2"
                        onClick={() => insertHashtag(tag)}
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <IonItem
                className="w-96 max-w-full mt-4"
                style={{
                  "--padding-start": "0px",
                }}
              >
                <IonCol style={{ padding: 0 }}>
                  <IonRow>
                    <p className="text-sm  block">Instagram Caption</p>
                  </IonRow>
                  <IonTextarea
                    value={description}
                    placeholder="Refer to the content direction and include our username and hashtags to continue"
                    onIonChange={(e) => setDescription(e.detail.value!)}
                  ></IonTextarea>
                </IonCol>
              </IonItem>

              <FormTextArea
                name="commentsToBrand"
                placeholder="Give some private feedback of your experience?"
                control={control}
                label="his comment will not be published to your feed"
              />

              <div className="flex flex-col">
                <IonButton
                  className=" mt-8"
                  fill="outline"
                  style={{
                    "--shadow": 0,
                  }}
                  onClick={() => copyToClipboardOnClick()}
                >
                  Copy caption
                </IonButton>
              </div>

              <div className="flex flex-col">
                <IonButton
                  fill="outline"
                  style={{
                    "--shadow": 0,
                  }}
                  disabled={submittingContent || submitButtonDisabled()}
                  onClick={() => setShowConfirmModal(true)}
                >
                  Submit
                </IonButton>
              </div>
            </form>

            <IonModal isOpen={showConfirmModal}>
              <IonContent fullscreen>
                <IonHeader class="ion-no-border">
                  <IonToolbar>
                    <IonButton
                      slot="start"
                      fill="clear"
                      size="large"
                      onClick={() => setShowConfirmModal(false)}
                    >
                      <IonIcon icon={arrowBack} />
                    </IonButton>
                    <IonTitle>{campaign.brandName} Guidelines</IonTitle>
                  </IonToolbar>
                </IonHeader>
                {/* Dos and don'ts */}
                <div className="flex flex-col justify-center text-lg ml-4 mr-4 mt-4 place-content-center place-items-center">
                  <IonLabel className="place-items-center text-lg place-content-center">
                    Check you’ve met these content guidelines, otherwise
                    approval is unlikely
                  </IonLabel>
                  {campaign.rules && (
                    <React.Fragment>
                      <IonLabel className="font-bold mt-10">
                        Content Requirements:
                      </IonLabel>
                      {campaign.rules.map((rule) => (
                        <p key={rule} className="text-center">
                          {rule}
                        </p>
                      ))}
                    </React.Fragment>
                  )}
                  {campaign.captionRules && (
                    <React.Fragment>
                      <IonText className=" font-bold">
                        Caption Requirements:
                      </IonText>
                      {campaign.captionRules.map((captionRules) => (
                        <p key={captionRules} className=" text-center">
                          {captionRules}
                        </p>
                      ))}
                    </React.Fragment>
                  )}

                  <br />
                  <IonButton
                    onClick={() => onConfirmSubmission()}
                    fill="outline"
                    disabled={submittingContent}
                    style={{
                      "--shadow": 0,
                    }}
                  >
                    Confirm Submission
                  </IonButton>
                </div>
              </IonContent>
            </IonModal>
          </React.Fragment>
        )}
      </IonContent>
    </IonPage>
  );
};

export default VideoSubmission;
