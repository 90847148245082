import Auth from "@aws-amplify/auth";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSlide,
  IonSlides,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Commit from "@socialplug/common/interfaces/commit";
import { useApi } from "@socialplug/common/utilities/api";
import Divider from "@uiw/react-divider";
import { compareAsc } from "date-fns";
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  location,
  time,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";

const VerifyCampaignOffer = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>();
  const [commits, setCommits] = useState<Commit[]>([]);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [displayQrCode, setDisplayQrCode] = useState(false);
  const [campaignExpired, setCampaignExpired] = useState(false);
  const [, fetchCampaign] = useApi("GET", "campaign", "fetch");
  const [, listCommits] = useApi("GET", "commit", "list");
  const [, createCommit] = useApi("POST", "commit", "create");

  useEffect(() => {
    (async () => {
      try {
        //replace this with the campaign to become verified
        const foundCampaign = await fetchCampaign({
          queryParams: { campaignId: "609a4b07b4a722ef26ad6e8e" },
        });
        setSelectedCampaign(foundCampaign);
        setCampaignExpired(
          compareAsc(new Date(foundCampaign.endDate), new Date()) === -1
        );
        const userId = await Auth.currentAuthenticatedUser();
        const usersCommits: Commit[] = await listCommits({
          queryParams: {
            userId: userId.username,
            campaignId: foundCampaign.id,
          },
        });
        if (usersCommits.length > 0) {
          setAlreadyApplied(true);
          setDisplayQrCode(true);
        }
        const allCommits: Commit[] = await listCommits({
          queryParams: {
            campaignId: foundCampaign.id,
          },
        });
        setCommits(allCommits);
      } catch {}
    })();
  }, [fetchCampaign, listCommits]);

  const getHashTags = () => {
    var toReturn = "";
    selectedCampaign?.hashtags.forEach((hashtag) => {
      toReturn += hashtag + " ";
    });
    return toReturn;
  };

  const getNumberOfRedemptionsLeft = () => {
    if (selectedCampaign?.maximumRedemptions !== undefined) {
      var toReturn = selectedCampaign.maximumRedemptions;
      commits?.forEach((commit) => {
        if (commit.state !== "AwaitingApproval") {
          toReturn--;
        }
      });
      return toReturn;
    }
  };

  const requestAccess = async () => {
    if (!alreadyApplied) {
      if (selectedCampaign?.type === "Online") {
        await createCommit({
          body: {
            campaignId: selectedCampaign?.id,
          },
        });
        setAlreadyApplied(true);
      } else if (selectedCampaign?.type === "Physical") {
        await createCommit({
          body: {
            campaignId: selectedCampaign?.id,
          },
        });
        setDisplayQrCode(true);
        setAlreadyApplied(true);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{ "--background": "#1F2937" }}>
          <IonButtons slot="start" className="fill-current text-yellow-300">
            <IonBackButton defaultHref="/home/campaigns" />
          </IonButtons>
          {selectedCampaign !== undefined && (
            <IonTitle className="text-yellow-300">
              {selectedCampaign?.brandName} - {selectedCampaign?.title}
            </IonTitle>
          )}
          {selectedCampaign === undefined && (
            <IonTitle className="text-yellow-300">Error</IonTitle>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen style={{ "--background": "#1F2937" }}>
        <div className="flex flex-col place-content-center place-items-center">
          {selectedCampaign !== undefined && (
            <React.Fragment>
              <IonCard className="lg:w-2/6">
                <div className="place-content-center place-items-center flex flex-col">
                  <img
                    src={selectedCampaign.coverImage?.url}
                    style={{ maxHeight: 200 }}
                    className="mb-2 w-full max-h-64 lg:mt-2"
                    alt="campaign cover"
                  />
                  <IonAvatar slot="start" className="">
                    <img
                      src={selectedCampaign.coverImage?.url}
                      alt="user avatar"
                    />
                  </IonAvatar>
                  <p className="font-bold text-xl">
                    {selectedCampaign?.brandName}
                  </p>
                  <React.Fragment>
                    {selectedCampaign.type === "Physical" && (
                      <p className="fill-current text-gray-500">
                        <IonIcon icon={location} className="mr-2" /> 0.37km away
                      </p>
                    )}
                    <p className="fill-current text-gray-500">
                      <IonIcon icon={time} className="mr-2" />
                      {new Date(
                        selectedCampaign.startDate
                      ).toLocaleDateString()}
                    </p>
                    <p>{selectedCampaign?.description}</p>
                  </React.Fragment>
                </div>

                <Divider />
                <div className="flex flex-col place-content-center place-items-center">
                  {selectedCampaign.type === "Physical" && (
                    <React.Fragment>
                      <p className="font-bold">ADDRESS</p>
                      <p className="text-center">
                        {selectedCampaign.addressFormatted}
                      </p>
                      <Divider />
                    </React.Fragment>
                  )}
                  <React.Fragment>
                    <p className="font-bold">At Handle</p>
                    <p>{selectedCampaign.atHandle}</p>
                    <Divider />
                  </React.Fragment>

                  {selectedCampaign.hashtags !== null &&
                    selectedCampaign.hashtags !== undefined && (
                      <React.Fragment>
                        <p className="font-bold">Hashtags</p>
                        <p>{getHashTags()}</p>
                        <Divider />
                      </React.Fragment>
                    )}

                  <p className="font-bold">REWARD</p>
                  <p className="text-center m-2">
                    {selectedCampaign.rewardType} -{" "}
                    {selectedCampaign.rewardDescription}
                  </p>
                  <Divider />
                </div>

                <div className="flex flex-col place-content-center place-items-center">
                  <p className="font-bold">MOOD BOARD</p>

                  <IonSlides className="mt-2 w-1/2" mode="ios" pager>
                    {selectedCampaign.moodboard.map((img) => {
                      return (
                        <IonSlide key={img.id}>
                          <img src={img.url} alt="mood" />
                        </IonSlide>
                      );
                    })}
                  </IonSlides>
                  <Divider />
                  <p className="font-bold">AVAILABLE UNTIL</p>
                  <p className="mb-1 text-center ml-2 mr-2">
                    Until{" "}
                    {new Date(selectedCampaign.endDate).toLocaleDateString()} or
                    there is no redemptions remaining or budget is spent.
                  </p>
                  <Divider />
                  <p className="font-bold place-items-center place-content-center ">
                    <IonIcon
                      size="large"
                      slot="start"
                      icon={checkmarkCircleOutline}
                      className="float-left fill-current text-green-500 "
                    />
                    CONTENT GUIDELINES
                  </p>
                  <ul className="clear-both  place-items-center place-content-center">
                    {selectedCampaign.rules.map((rule) => (
                      <li className="list-disc" key={rule}>
                        {rule}
                      </li>
                    ))}
                  </ul>
                  <Divider />
                  <p className="font-bold place-content-center place-items-center">
                    <IonIcon
                      size="large"
                      icon={closeCircleOutline}
                      className="float-left fill-current text-red-500"
                    />
                    CAPTION GUIDELINES
                  </p>
                  <ul className="clear-both place-items-center place-content-center">
                    {selectedCampaign.captionRules.map((captionRules) => (
                      <li className="list-disc" key={captionRules}>
                        {captionRules}
                      </li>
                    ))}
                  </ul>
                  <Divider />
                  <p className="font-bold">PARTNERSHIP TAG</p>
                  <p className="mb-2">{selectedCampaign.atHandle}</p>
                  <Divider />
                  <p className="font-bold">REDEMPTIONS REMAINING</p>
                  <p className="mb-2">{getNumberOfRedemptionsLeft()}</p>
                  <Divider />
                  <p className="font-bold">ADDITIONAL TAGS</p>
                  <p className="mb-5">#placeholder</p>
                </div>

                {displayQrCode &&
                  alreadyApplied &&
                  selectedCampaign.type === "Physical" && (
                    <div className="flex flex-col place-items-center place-content-center">
                      <Divider />
                      <QRCode
                        value="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                        logoImage="https://i.imgur.com/5ijJp4O.png"
                        size={300}
                        logoWidth={400}
                        logoHeight={400}
                        logoOpacity={0.5}
                        qrStyle={"dots"}
                      />

                      <IonText className="ml-2 mr-2 text-center text-xl font-bold">
                        Ask a member of staff at <br />{" "}
                        {selectedCampaign?.brandName},{" "}
                        {selectedCampaign?.addressFormatted} <br /> to scan this
                        QR code to claim your reward
                      </IonText>
                    </div>
                  )}
              </IonCard>

              {selectedCampaign.type === "Online" && !campaignExpired && (
                <div className=" bg-gray-800 h-10 ">
                  <IonButton
                    onClick={() => requestAccess()}
                    className="text-yellow-300"
                    fill="outline"
                    style={{
                      "--shadow": 0,
                      "--border-color": "yellow",
                    }}
                    disabled={alreadyApplied}
                  >
                    {alreadyApplied ? "ALREADY REQUESTED" : "REQUEST ACCESS"}
                  </IonButton>
                </div>
              )}

              {selectedCampaign.type === "Physical" && !campaignExpired && (
                <div className="bg-gray-800 h-10">
                  <IonButton
                    className="text-yellow-300"
                    fill="outline"
                    style={{
                      "--shadow": 0,
                      "--border-color": "yellow",
                    }}
                    onClick={() => requestAccess()}
                    disabled={
                      alreadyApplied || getNumberOfRedemptionsLeft() === 0
                    }
                  >
                    {alreadyApplied ? "ALREADY APPLIED" : "PROMISE TO COMMIT"}
                  </IonButton>
                </div>
              )}
            </React.Fragment>
          )}
          {selectedCampaign === null && (
            <React.Fragment>
              <p className="text-red-500">
                Error, no campaign with that ID found.
              </p>
            </React.Fragment>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default VerifyCampaignOffer;
