import { IonIcon } from "@ionic/react";
import { imageOutline } from "ionicons/icons";
import React from "react";
import { Control, useFieldArray } from "react-hook-form";
import ImageUploadField, { ImageOutput } from "./ImageUploadField";

type ImageSelectionGridProps = {
  campaignId: string;
  name: string;
  control: Control<any>;
} & React.HTMLAttributes<HTMLDivElement>;

interface ImageThumbnailProps {
  image?: ImageOutput;
  showUpload?: boolean;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  image,
  showUpload,
}) => {
  image = image || { id: "", url: "" };
  const hasImage = image.id && image.id !== "";

  return (
    <div
      className={`rounded-lg relative border-2 bg-white w-24 h-24 min-w-max overflow-hidden`}
    >
      <div
        className="absolute w-full h-full flex items-center justify-evenly hover:opacity-80"
        style={
          hasImage
            ? {
                background: `url(${image?.url}) center`,
                backgroundSize: "cover",
              }
            : {}
        }
      >
        {!hasImage && showUpload && <IonIcon icon={imageOutline} />}
      </div>
    </div>
  );
};

const ImageSelectionGrid: React.FC<ImageSelectionGridProps> = ({
  name,
  campaignId,
  control,
  className,
  ...props
}) => {
  const fieldArray = useFieldArray({
    control,
    name,
  });

  const getRemainingThumbnails = () => {
    const thumbs = [];

    while (fieldArray.fields.length + thumbs.length < 5) {
      thumbs.push(
        <ImageThumbnail
          key={`thumb-${fieldArray.fields.length + thumbs.length}`}
        ></ImageThumbnail>
      );
    }

    return thumbs;
  };

  const onImageClick = (field: any) => {
    if (fieldArray.fields.length > 0) {
      const index = fieldArray.fields.indexOf(field);
      if (index > -1) {
        fieldArray.fields.splice(index, 1);
        fieldArray.remove(index);
      }
    }
  };

  return (
    <div
      className={`grid grid-cols-3 grid-flow-row gap-4 max-w-xs mx-auto ${className}`}
      {...props}
    >
      {fieldArray.fields.map((field) => (
        <div onClick={() => onImageClick(field)}>
          <ImageThumbnail key={field.id} image={field as ImageOutput} />
        </div>
      ))}

      <ImageUploadField
        purpose="CampaignCommit"
        campaignId={campaignId}
        id="mb-new-image"
        onImageUploaded={(image) => fieldArray.append(image)}
      >
        <ImageThumbnail showUpload={true} key="mb-new-image" />
      </ImageUploadField>

      {getRemainingThumbnails()}
    </div>
  );
};

export default ImageSelectionGrid;
