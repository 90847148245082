import React from "react";
export interface NotificationBadgeContainer {
  notificationBadgeValue: number;
  setNotificationBadgeValue: (badgeValue: number) => void;
}
const NotificationBadgeContext = React.createContext<NotificationBadgeContainer>(
  {
    notificationBadgeValue: 0,
    setNotificationBadgeValue: () => {},
  }
);

export default NotificationBadgeContext;
