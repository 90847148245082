import {
  IonButton,
  IonCard,
  IonCardTitle,
  IonIcon,
  IonInput,
  IonText,
} from "@ionic/react";
import Campaign from "@socialplug/common/interfaces/campaign";
import Offer from "@socialplug/common/interfaces/offer";
import { useApi } from "@socialplug/common/utilities/api";
import { cashOutline, giftOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

const OfferCard: React.FC<any> = (props) => {
  const [offer, setoffer] = useState<Offer>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [declined, setDeclined] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [offerTextField, setOfferTextField] = useState("");
  const [lowBallOfferTextField, setLowBallOfferTextField] = useState("");
  const [showCounterOffer, setShowCounterOffer] = useState(false);
  const [counterOfferEnabled, setCounterOfferEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  let history = useHistory();

  const [, editOffer] = useApi("POST", "offer", "edit");
  const [, createOffer] = useApi("POST", "offer", "create");

  useEffect(() => {
    try {
      setCampaign(props.campaign);
      setoffer(props.offer);
    } catch {}
  }, [props.campaign, props.offer]);

  useEffect(() => {
    switch (campaign?.rewardType) {
      case "Discount":
        if (parseInt(offerTextField) <= 100) {
          setCounterOfferEnabled(true);
          setErrorMessage("");
        } else if (offerTextField !== "") {
          setCounterOfferEnabled(false);
          setErrorMessage("Enter discount percentage (1-100)");
        }
        break;
      case "Money":
        if (parseInt(offerTextField) > 0) {
          setCounterOfferEnabled(true);
          setErrorMessage("");
        } else if (offerTextField !== "") {
          setCounterOfferEnabled(false);
          setErrorMessage("Enter price of money reward");
        }
        break;
      default:
        break;
    }
  }, [offerTextField, campaign?.rewardType]);

  const onAcceptClick = () => {
    try {
      (async () => {
        await editOffer({
          body: {
            offerId: offer?.id,
            changes: {
              accepted: true,
            },
          },
        });
      })();
      setAccepted(true);
      props.onAccept();
    } catch {}
  };

  const onDeclineClick = () => {
    if (
      campaign?.rewardType === "Money" ||
      campaign?.rewardType === "Discount"
    ) {
      setDeclined(true);
      setShowCounterOffer(true);
    } else {
      setDeclined(true);
    }
  };

  const makeCounterOffer = () => {
    if (offerTextField !== "" && !errorMessage) {
      try {
        (async () => {
          await createOffer({
            body: {
              campaignId: offer?.campaignId,
              commitId: offer?.commitId,
              offeredToId: offer?.offeredById,
              isCounterOffer: true,
              amount: parseFloat(offerTextField),
              lowAmount: parseFloat(lowBallOfferTextField),
              previousOfferId: offer?.id,
            },
          });
        })();
      } catch {}
      setSuccess(true);
      history.push(`/home/content`);
    }
  };

  const RewardIcon = () => {
    return campaign?.rewardType === "Gift" ? (
      <IonIcon
        size="large"
        icon={giftOutline}
        className="fill-current text-black"
      />
    ) : (
      <IonIcon
        size="large"
        icon={cashOutline}
        className="fill-current text-black"
      />
    );
  };

  return (
    <div className="flex flex-col place-items-center place-content-center">
      <IonCard className="bg-white w-5/6 lg:w-1/3 place-items-center place-content-center">
        <div className="grid grid-cols-11">
          <IonCardTitle className="col-span-9">
            Offer from {campaign?.brandName} <br /> for {campaign?.title}
          </IonCardTitle>
          <div className="float-right">
            <RewardIcon />
          </div>
        </div>
        <IonText className="text-black text-lg">
          £{offer?.amount} to post content
        </IonText>

        <br />
        <IonButton
          disabled={declined || accepted}
          className="text-blue-500"
          fill="outline"
          onClick={() => onAcceptClick()}
        >
          Accept
        </IonButton>
        <IonButton
          disabled={declined || accepted}
          onClick={() => onDeclineClick()}
          className="text-red-500"
          fill="outline"
          color="danger"
        >
          Negotiate
        </IonButton>

        {declined && showCounterOffer && (
          <React.Fragment>
            <div className="flex place-content-center place-items-center">
              <h1 className="font-bold text-xl">£</h1>
              <IonInput
                className="float-right text-xl"
                placeholder="Make counter offer"
                disabled={success}
                value={offerTextField}
                type="number"
                onIonChange={(e) => setOfferTextField(e.detail.value!)}
              />
            </div>
            <div className="flex place-content-center place-items-center">
              <h1 className="font-bold text-xl">£</h1>
              <IonInput
                className="clear-both text-xl"
                placeholder="Lowest price you will accept"
                disabled={success}
                value={lowBallOfferTextField}
                type="number"
                onIonChange={(e) => setLowBallOfferTextField(e.detail.value!)}
              />
            </div>
            <IonButton
              onClick={() => makeCounterOffer()}
              disabled={!counterOfferEnabled || success}
            >
              Send counter offer
            </IonButton>
          </React.Fragment>
        )}

        {errorMessage && (
          <IonText className="text-red-500 text-sm">
            <br />
            {errorMessage}
          </IonText>
        )}
        {success && (
          <IonText className="text-green-500 text-sm">
            <br />
            Counter offer made
          </IonText>
        )}
        {accepted && (
          <IonText className="text-green-500 text-sm">
            <br />
            Offer accepted
          </IonText>
        )}
      </IonCard>
    </div>
  );
};

export default OfferCard;
